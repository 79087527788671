import $ from "jquery";
import detailDefault from "../../detailDefault";
export default class detailOpportunities extends detailDefault{
    load(sAction, data) {
        const id = sAction.dataGet(data.prefix+"/id");
        if (!id) {
            this.setDefaultValues(sAction, data);
        }
        const sales_stage = sAction.dataGet(data.prefix+"/fields/sales_stage/value");
        this.checkDateDue(sAction, data, sales_stage);
        if (
            sales_stage == 'invoice_request' ||
            sales_stage == 'invoiced' ||
            sales_stage == 'finished' ||
            sales_stage == 'lost' ||
            sales_stage == 'closed_canceled_client' ||
            sales_stage == 'closed_canceled_me'
        ) {
            sAction.dsClear();
            // sAction.dsAdd("set", data.prefix+"/fields/date_from/def/readonly", true);
            // sAction.dsAdd("set", data.prefix+"/fields/date_to/def/readonly", true);
            if (!sAction.dataGet(data.prefix+'/fields/date_from/value')) {
                sAction.dsAdd("set", data.prefix+"/fields/date_from/customClass", 'error');
            }
            if (!sAction.dataGet(data.prefix+'/fields/date_to/value')) {
                sAction.dsAdd("set", data.prefix+"/fields/date_to/customClass", 'error');
            }
            sAction.dsProcess();
        }
        this.setDateRequired(sAction, data);
        // if( sales_stage == "Closed Lost" ||
        //     sales_stage == "Closed canceled client" ||
        //     sales_stage == "Closed canceled me"
        //     ){
        //         sAction.dsClear();
        //         sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
        //         sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", true);
        //         sAction.dsAdd("set", data.prefix+"/fields/pripominky_k_uzavreni_c/def/readonly", false);

        //         sAction.dsProcess();

        //     }
        //     else{
        //         sAction.dsClear();
        //         if(sales_stage == "Closed Won"){
        //             sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", false);
        //             $("#duvod_uzavreni_c").closest(".detailViwRow").css("visibility", "hidden");   
        //         }
        //         else{
        //             sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/readonly", true);
        //             $("#duvod_uzavreni_c").closest(".detailViewTabContentRow").prevAll(".detailViewPanelHeader:first").css("display", "none");
        //             $("#duvod_uzavreni_c").closest(".detailViewTabContentRow").css("display", "none");  
        //         }
                
        //         sAction.dsAdd("set", data.prefix+"/fields/duvod_uzavreni_c/def/required", false);
        //         sAction.dsProcess();
        //     }
    }

    update(sAction,data) {
        switch (data.field) {
            case 'account_id':
                sAction.rest.post('getSource', {id: data.value.id}, returnData => {
                    sAction.dsClear();
                    sAction.dsAdd("set",data.prefix+"/fields/source/value",returnData['source']);
                    sAction.dsAdd("set",data.prefix+"/changes/fields/source",returnData['source']);
                    sAction.dsProcess();
                });
                break;
            case 'sales_stage':
                // Oveření datumu splatnosti
                this.checkDateDue(sAction, data, data.value, null);
                // Nastavení readonly pokud už je obchod započat
                if (
                    [
                        'invoice_request',
                        'invoiced',
                        'finished',
                        'lost',
                        'closed_canceled_client',
                        'closed_canceled_me',
                    ].includes(data.value)
                ) {
                    sAction.dsClear();
                    // sAction.dsAdd("set", data.prefix+"/fields/date_from/def/readonly", true);
                    // sAction.dsAdd("set", data.prefix+"/fields/date_to/def/readonly", true);
                    if (!sAction.dataGet(data.prefix+'/fields/date_from/value')) {
                        sAction.dsAdd("set", data.prefix+"/fields/date_from/customClass", 'error');
                    }
                    if (!sAction.dataGet(data.prefix+'/fields/date_to/value')) {
                        sAction.dsAdd("set", data.prefix+"/fields/date_to/customClass", 'error');
                    }
                    sAction.dsProcess();
                } else {
                    sAction.dsClear();
                    sAction.dsAdd("set", data.prefix+"/fields/date_from/customClass", '');
                    sAction.dsAdd("set", data.prefix+"/fields/date_to/customClass", '');
                    sAction.dsProcess();
                }
                if (['invoice_request', 'invoiced', 'finished', 'agreed', 'offer_sent'].includes(data.value)) {
                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/canSave', false);
                    sAction.dsProcess();

                    let id = sAction.dataGet(data.prefix + '/id');
                    sAction.rest.post('canUserChangeOppStage', {oppID: id, stage: data.value}, returnData => {
                        if (returnData.status === true) {
                            sAction.dsClear();
                            sAction.dsAdd('set', data.prefix + '/fields/user_name/customClass', '');
                            sAction.dsAdd('set', data.prefix + '/canSave', true);
                            sAction.dsProcess();
                        } else {
                            sAction.dsClear();
                            sAction.dsAdd('set', data.prefix + '/fields/sales_stage/value', data.prevValue);
                            sAction.dsAdd('set', data.prefix + '/changes/fields/sales_stage', data.prevValue);
                            sAction.dsAdd('set', data.prefix + '/canSave', true);
                            sAction.dsProcess();
                            sAction.error(sAction.translate(returnData.errorMessage.text));
                        }
                    });
                }
                if (['finished', 'invoiced'].includes(data.prevValue) && data.value === 'new') {
                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/canSave', false);
                    sAction.dsProcess();

                    let id = sAction.dataGet(data.prefix + '/id');
                    sAction.rest.post('canUserChangeOppStage', {oppID: id, stage: data.value, prevStage: data.prevValue}, returnData => {
                        if (returnData.status === true) {
                            sAction.dsClear();
                            sAction.dsAdd('set', data.prefix + '/fields/user_name/customClass', '');
                            sAction.dsAdd('set', data.prefix + '/canSave', true);
                            sAction.dsProcess();
                        } else {
                            sAction.dsClear();
                            sAction.dsAdd('set', data.prefix + '/fields/sales_stage/value', data.prevValue);
                            sAction.dsAdd('set', data.prefix + '/changes/fields/sales_stage', data.prevValue);
                            sAction.dsAdd('set', data.prefix + '/canSave', true);
                            sAction.dsProcess();
                            sAction.error(sAction.translate(returnData.errorMessage.text));
                        }
                    });
                }
                break;
            case 'date_due':
                this.checkDateDue(sAction, data, null, data.value);
                break;
            case 'date_from':
            case 'date_to':
                this.setDateRequired(sAction, data);
        }
    }
    checkDateDue(sAction, data, salesStage = null, dateDue = null) {
        dateDue = dateDue || sAction.dataGet(data.prefix+"/fields/date_due/value");
        salesStage = salesStage || sAction.dataGet(data.prefix+"/fields/sales_stage/value");
        var currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        const dateDueValue = new Date(dateDue);
        if (currentDate > dateDueValue && salesStage !== 'finished' && dateDue) {
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix+"/fields/date_due/customClass", 'error');
            sAction.dsProcess();
        } else {
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix+"/fields/date_due/customClass", '');
            sAction.dsProcess();
        }
    }
    beforeSave(sAction, data){
        const changes = sAction.dataGet(data.prefix + '/changes/fields');
        if (changes.get('assigned_user_id') === '' || changes.get('users_opportunities_1users_ida') === '' || changes.get('users_opportunities_2users_ida') === '') {
            sAction.confrim('Byl smazán přiřazený uživatel, konzultant nebo kdo sehnal. Uložením záznamu je smažete i z provizí. Opravdu chcete záznam uložit ?', () => {
                sAction.popupHide();
                sAction.saveRecordBase(data.paramData, data.callback, true);
            });
            return false;
        }

        let dateFrom = sAction.dataGet(data.prefix + '/fields/date_from/value');
        let dateTo = sAction.dataGet(data.prefix + '/fields/date_to/value');
        if (!dateTo !== !dateFrom) {
            sAction.error(sAction.translate('LBL_ERROR_NEED_FILL_BOTH_DATES'));
            return false;
        }

        return true;
      }
      setDefaultValues(sAction, data) {
        const userID = sAction.dataGet('conf/user/id');
        const userName = sAction.dataGet('conf/user/name');
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix+"/fields/users_opportunities_2_name/value", userName);
        sAction.dsAdd("set", data.prefix+"/fields/users_opportunities_2_name/def/id_value", userID);
        sAction.dsAdd("set", data.prefix+"/changes/fields/users_opportunities_2users_ida", userID);
        sAction.dsProcess();
      }

    setDateRequired(sAction, data) {
        let dateFrom = sAction.dataGet(data.prefix + '/fields/date_from/value');
        let dateTo = sAction.dataGet(data.prefix + '/fields/date_to/value');
        sAction.dsClear();
        let required = dateFrom || dateTo;
        sAction.dsAdd('set', data.prefix + '/fields/date_from/def/required', required);
        sAction.dsAdd('set', data.prefix + '/fields/date_to/def/required', required);
        sAction.dsProcess();
    }
}
