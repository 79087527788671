export default function listPagination(data) {
    this.dsClear();

    const offset = data.offset;
    const limit = data.limit;
    const rowCount = data.rowCount;
    const rowTotalCount = data.rowTotalCount
    const page = data.page;
    const prefix = data.prefix;
    const type = data.type;
    let list = null;

    if (this.getViewName() === 'import') {
        const action = this.getStorage('prepareListStructure');
        list = this.prepareListStructure(action);
    }

    if (type == "start") {
        if (offset != 0) {
            this.reloadList(0, 1, prefix, null, rowTotalCount, list);
        }
    } else if (type == "back") {

        if (offset >= limit) {
            this.reloadList(offset - limit, page - 1, prefix, null, rowTotalCount, list)
        }
    } else if (type == "next") {
        console.log(rowCount, offset, limit);
        if (rowCount == limit) {
            this.reloadList(offset + limit, page + 1, prefix, null, rowTotalCount, list)
        }
    }
    else if (type == "end") {
        const maxPage = Math.floor(rowTotalCount / limit)
        const maxOffset = limit * maxPage
        this.reloadList(maxOffset, maxPage +1, prefix, null, rowTotalCount, list)
    }
}