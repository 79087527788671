import React, { Component } from "react";
import PureComponent from "../pure";
import InviteesContainer from "../popup/calendarWindow/inviteesContainer";


class DetailInvTabComponent extends PureComponent {

    render(){
        const active = this.props.active;
        const module = this.props.module;
        const way = this.props.way;
        
        let data = null;
        if(this.props.data != null){
            data = this.props.data.toJS();
        }
        
        let pomClass = "detailViewTabContentBlok detailInvComp";


        if(active){
            pomClass += " select";
        }

        return(
        <div className={pomClass}>
            {data &&
            <InviteesContainer data={data.invitees} prefix={way+"/invitees"} pomClass="detailView" />
            }
        </div>
        );
    }

}
export default DetailInvTabComponent;