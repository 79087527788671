export default function getSavedPrintFile(data, callback = null) {
  data["returnFile"] = true;
  data["action"] = "printPdf"
  this.load();
  this.filesXhr({
    action: "generateFileRequest",
    data: data,
    success: ret => {
      this.unLoad();

      if (!ret) {
        // Nepodařilo se spojit se serverem nebo získat data, opakujte prosím akci.
        this.error(this.translate("ERR_SEND_MASS_EMAIL_NO_DATA", "Emails"));
      } else if (ret.id == undefined) {
        // Data nebyla vrácena ve správném formátu. Výstup:
        this.error(
          this.translate("ERR_SEND_MASS_EMAIL_FORMAT", "Emails") + ret
        );
      } else {
        this.load();
        this.rest.post(
          "email/getAccountEmail",
          { module: data.module, id: data.id },
          email => {
            this.unLoad();

            const emailData = {
              parent_type: data.module,
              parent_id: data.id,
              parent_name: data.name,
              callback: callback,

              to_addrs: email.emails || "",

              attachments: {
                [ret.id]: {
                  id: ret.id,
                  name: data.filename || (data.name + "." + (data.filetype || "pdf")),
                  size: ret.size,
                  source: "cache",
                  type: (data.filetype || "pdf")
                }
              },

              recordModule: data.module,
              recordId: data.id,
              keepSelf: true,
            };

            this.openEmailPopup(emailData);
          }
        );
      }
    },
    error: message => {
      this.unLoad();
      this.error(this.translate(message));
    }
  });
}
