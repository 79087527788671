import detailDefault from "../../detailDefault";

export default class detailAccounts extends detailDefault {
  load(sAction, data) {
    this.updateDetailApiVisibility(sAction, data);

    this.setRequiredICO(sAction, data);
  }

  update(sAction, data) {
    if (data.field.includes('billing_address') && sAction.dataGet(data.prefix + '/fields/copy_address/value') == 1) {
        sAction.dsClear();
        const field = data.field.replace('billing', 'shipping');
        sAction.dsAdd('set', data.prefix + '/fields/' + field + '/value', data.value);
        sAction.dsAdd('set', data.prefix + '/changes/fields/' + field, data.value);
        sAction.dsProcess();
    }

    switch (data.field) {
      case "billing_address_country":
        this.updateDetailApiVisibility(sAction, data)
          this.setRequiredICO(sAction, data);
        break;
      case 'company_type':
        this.setRequiredICO(sAction, data, data.value);
        break;
    }
  }

  //fce skryva/odkryva detail fieldy podle zeme, jestli je zeme CZECH REPUBLIC
  updateDetailApiVisibility(sAction, data) {
    let address;
    if (data.value) {
      address = data.value;
    } else {
      address = sAction.dataGet(data.prefix + "/fields/billing_address_country/value");
    }

    const updateFieldApiValue = (fieldName, fieldValue) => {
      if (sAction.dataGet(data.prefix + "/fields/"+fieldName) !== undefined) {
        sAction.dsAdd("set", data.prefix + "/fields/"+fieldName+"/def/showDetailApi", fieldValue);
      }
    }

    sAction.dsClear();
    if (!address || address === "CZECH REPUBLIC") {
      //zobrazi ikony
      updateFieldApiValue("name", "ApiName");
      updateFieldApiValue("account_name", "ApiName");
      updateFieldApiValue("ticker_symbol", "ApiICO");
      updateFieldApiValue("sic_code", "ApiDIC");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "ApiPSC");
      updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
    }
    else if(address === "SLOVAKIA"){
      //skryje ikony
      updateFieldApiValue("name", "ApiNameSK");
      updateFieldApiValue("account_name", "ApiNameSK");
      updateFieldApiValue("ticker_symbol", "ApiICOSlovakia");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "ApiStreet");
      updateFieldApiValue("shipping_address_street", "ApiStreet");
      updateFieldApiValue("billing_address_postalcode", "ApiPSC");
      updateFieldApiValue("shipping_address_postalcode", "ApiPSC");
    }
    else{
      //skryje ikony
      updateFieldApiValue("name", "ApiNameOthers");
      updateFieldApiValue("account_name", "");
      updateFieldApiValue("ticker_symbol", "");
      updateFieldApiValue("sic_code", "");
      updateFieldApiValue("billing_address_street", "");
      updateFieldApiValue("shipping_address_street", "");
      updateFieldApiValue("billing_address_postalcode", "");
      updateFieldApiValue("shipping_address_postalcode", "");
    }

    //Skryvani/odkryvani tabu Finstat kdyz je nebo neni zeme Slovensko
    const tabs = sAction.dataGet(data.prefix + "/tabs");
    if (tabs) {
      const finstatIndex = tabs.findIndex(i => i.name === "LBL_DETAILVIEW_PANEL_FINSTAT");
      if (finstatIndex !== -1) {
        if (address !== "SLOVAKIA") {
          sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", true);
        } else {
          sAction.dsAdd("set", data.prefix + "/tabs/" + finstatIndex + "/hidden", false);
        }
      }
    }
    sAction.dsProcess();
  }

    setRequiredICO(sAction, data, companyType = null) {
        if (companyType === null) {
            companyType = sAction.dataGet(data.prefix + '/fields/company_type/value');
        }
        const country = sAction.dataGet(data.prefix + '/fields/billing_address_country/value');

        sAction.dsClear();
        if(data.view != "list"){
            let required = companyType !== 'fyzicka' && country === 'CZECH REPUBLIC';
          sAction.dsAdd('set', data.prefix + '/fields/ticker_symbol/def/required', required);
        }
        sAction.dsProcess();
    }
}
