import DefaultCurrency_id from '../../defaultFields/Currency_id/Currency_id'
import  sAction  from "sAction";
export default class Currency_id extends DefaultCurrency_id {
    saveField(data) {
        sAction.saveField(data);

        //zmena vsech hodnot na detailu
        const oldCur = this.props.data.value;
        const newCur = data.value;
        const prefix = data.way.split("/")[0];
        sAction.currencyConvertFields(prefix, oldCur, newCur);
      }
}
