import React, { Component } from "react";
import PureComponent from "../../pure";
import ReportWindowColumnsContainer from "./ReportWindowColumnsContainer";
import  sAction  from "sAction";

import DragAndDrop from "../../dragAndDrop/DragAndDropCoripo";

class ReportWindowColumns extends PureComponent {
  saveColumns(dragData) {
    var data = {
      dragData: dragData,
      wayPrefix: "reportWindow/columns/",
    }
    sAction.dragAndDropSave(data);
 

  }
  moveStart(data) {
    const way = data.element.dataset.way;
    sAction.dataSet("reportWindow/columns/" + way + "/def/show", false);
  }
  moveEnd(data) {
    if(data.resize == true){
      const element = data.element;
      const newWidth = element.clientWidth
      const parent = document.querySelector(".columnsLine");
      const parentWidth = parent.clientWidth;
      var percent = (newWidth/parentWidth)*100;
      const way = data.element.dataset.index;
      sAction.dataSet("reportWindow/columns/" + way + "/def/width", percent+"%");
    }else{
    this.saveColumns(data);
    }
  }

  render() {
    const data = this.props.data;
    var hiddenColumns = [];
    var showColumns = [];
    if (data.columns != null) {
      hiddenColumns = data.columns.hidden;
      showColumns = data.columns.active;
    }
    const prefix = this.props.way;

    return (
      <div ref="moveHolder" className="reportWindowColumnsContainer">
        
        <DragAndDrop
          containersClass=".reportWindowColumnsList"
          elementsClass=".reportWindowColumn"
          handleElementClass=".reportWindowColumnSpan"
          wayPrefix="reportWindow/columns/"
          moveStart={data => this.moveStart(data)}
          moveEnd={data => this.moveEnd(data)}
        >
          <div className="reportWindowColumnsHeaders">
            <h3>{sAction.translate("LBL_REPORT_HIDDEN")}</h3>
            <h3>{sAction.translate("LBL_REPORT_SHOWN")}</h3>
          </div>
          <div className="reportWindowColumnsHeightHolder">
 
            <ReportWindowColumnsContainer
              data={hiddenColumns}
              customClass="reportWindowColumns scrollHeight  columnsHidden"
              moveClass="reportWindowColumnsList"
              containerName={"hidden"}
              withModuleSelect={true}
              activeRelate={data.get("activeRelate")}
              prefix={prefix}
              notAllowedTypes={this.props.notAllowedTypes}
            />
            <ReportWindowColumnsContainer
              data={showColumns}
              customClass="reportWindowColumns scrollHeight  columnsActive"
              moveClass="reportWindowColumnsList"
              modulName={true}
              containerName={"active"}
              setOrder={true}
              setWidth={true}
              prefix={prefix}
            />
          </div>
        </DragAndDrop>
      </div>
    );
  }
}
export default ReportWindowColumns;
