export default function openReportWindow(){
    this.load();
    this.rest.get("modules",data => {
        this.unLoad();
        this.dsClear();
        this.dsAdd("set","reportWindow/modules",data);
        this.dsAdd("set","conf/popup/show",true)
        this.dsAdd("set","conf/popup/content","reportWindow")
        this.dsAdd("set","conf/popup/data/header",this.translate("LBL_REPORT_EXTENDED_SEARCH"))
        this.dsProcess();
    });
} 