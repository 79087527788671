export default function accCreateOpportunity(data) {

  this.detailPredefinedFields = [];
  //nazev firmy
  this.detailPredefinedFields.push({
    type: "relate_simple",
    fieldName: "account_name",
    name: "account_id",
    value: { id: data.id, "name": data.name }
  });

  //faze prilezitosti => sondovani
  this.detailPredefinedFields.push({
    type: "field",
    name: "sales_stage",
    value: "new"
  });

  // Zdroj
  this.detailPredefinedFields.push({
    type: "field",
    name: "source",
    value: this.dataGet(data.prefix + '/fields/source/value'),
  });
  this.detailNewRecord({
    module: "Opportunities",
    way: data.prefix
  });
}