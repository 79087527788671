import React from "react";
import PureComponent from "../../pure";
import sAction from "sAction";
import DatePicker from "../../formElements/DatePicker";
import Button from "../../formElements/Button";
import AcmDate from "../../formElements/AcmDate";

export default class SetOppDatePopup extends PureComponent {
    constructor(props) {
        super(props);
    
        this.state = {
          date_from: null,
          from_error: false,
          date_to: null,
          to_error: false,
        };
      }

    
    setValue(e) {
        this.setState(e);
    }
    save() {
        if (this.state.date_from && this.state.date_to) {
            const paramData = this.props.data.get('data').get('paramData');
            const callback = this.props.data.get('data').get('callback');
            sAction.saveOppRealization({date_from: this.state.date_from, date_to: this.state.date_to, id: this.props.data.get('data').get('id')}, () => {
                sAction.saveRecordBase(paramData.toJS(), callback, true);
                sAction.popupHide();
            });
        }
        this.setState({from_error: !this.state.date_from, to_error: !this.state.date_to});
    }
    close() {
        sAction.popupHide();
    }
    render() {
        return (
            <React.Fragment>
                <div className="reportWindowHeader">
                    <div className="reportWindowHeaderText">{sAction.translate('LBL_SET_OPP_DATE')}</div>
                </div>
                <div className='simpleForm'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='formlabel'>{sAction.translate('LBL_DATE_FROM', 'Opportunities')}</td>
                                <td>
                                    <AcmDate
                                        className='withBorder'
                                        onChange={e => this.setValue({date_from: e})}
                                        error={this.state.from_error}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className='formlabel'>{sAction.translate('LBL_DATE_TO', 'Opportunities')}</td>
                                <td>
                                    <AcmDate
                                        className='withBorder'
                                        onChange={e => this.setValue({date_to: e})}
                                        error={this.state.to_error}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2}>
                                    <div className="viewActionPanelButtons flexRight">
                                        <Button onClick={() => this.close()} className='popupCloseButton'>
                                            <div className={"actionPanelButtonIcon icon-closeIconLight"} />
                                            {sAction.translate('LBL_CANCEL_ACTION')}
                                        </Button>
                                        <Button onClick={() => this.save()}>
                                            <div className={"actionPanelButtonIcon icon-download"} />
                                            {sAction.translate('LBL_SAVE_RECORD')}
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }
}