import React, { Component } from 'react'
import PureComponent from '../../pure'

import  sAction  from "sAction";

import confProd from "./productLineConfig";
import Button from "../../formElements/Button";


class QGroupTotal extends PureComponent{

    // addProdLine(itway){
    //     //this.setState({show:true});
    //     sAction.updateDetailCustom("addProdLine", {way:itway+"/lines"})
    // }

    productCallback(way, item){

        if(item.id != undefined && item.id != ""){
            //product template has been selected
            const searchData = {
                fields: ["id"],
                getFields: ["id", "name", "cost_price", "list_price", "discount_price", "currency_id", "description", "mft_part_num", "mft_part_num_int", 'unit_c'],
                value: item.id,
                module : "ProductTemplates"
            };

            sAction.quickSearch(searchData, data => {
                if(data.length > 0){
                    var line = data[0];
                    //const currency_id = sAction.dataGet(way.split("/productData/", 1)[0]+"/currency_id");//TTT cesta natvrdo
                    const prefix = way.split("/")[0];

                    const currency_id = sAction.dataGet(prefix+"/fields/currency_id/value");
                    // let convRate = sAction.dataGet(prefix+"/fields/kurz/value");

                    line.cost_price = sAction.currencyConvert(line.cost_price, line.currency_id, currency_id/*, convRate*/);
                    line.list_price = sAction.currencyConvert(line.list_price, line.currency_id, currency_id/*, convRate*/);
                    line.discount_amount = sAction.currencyConvert(line.discount_amount ? line.discount_amount : 0, line.currency_id, currency_id/*, convRate*/);
                    // line.unit = line.unit_c;
                    line.unit = sAction.app_strings.unit_list[line.unit_c];
                    line.unit_c = null;
                    var newItem = line;
                    newItem.product_template_id = newItem.id;
                    newItem.id = "";
                    sAction.updateDetailCustom("addProdLine", {way: way + '/lines', item: newItem});
                }
            });
        }
    }

    openPopupList(itway) {
        var self = this;
        const data = {
            module: 'ProductTemplates',
            selectedActive: false,
            defaultFilter: this.props.defaultFilter
        };
        sAction.openRelatePopup(data, returnData => {
            self.productCallback(itway, returnData);
            sAction.popupHide();
        });
    }

    render(){
    var data = this.props.data;
    const way = this.props.way;
    const readonly = this.props.readonly;

    const sums = sAction.calcSums(way, {type : "group", data : data});
    const conf = confProd.sums;
    const ind = this.props.ind;

    const self = this;

    var renderComp = [];
    if(!readonly){
        renderComp.push(<Button 
            className="acmAddRowBtn alwaysGreen"
            key={"addBtn"}
            variant="text"
            onClick={() => this.openPopupList(way)}
            // onClick={() => self.addProdLine(way)}
        >
            <div className="buttonIcon icon-addRowIcon" />
        {sAction.translate("LBL_ADD_ROW", "Quotes")}
        </Button>);
        renderComp.push(
            <div key="firstFiller" style={{width:"34px"}} />
        );
    }
    else{
        renderComp.push(
            <div key="firstFiller" style={{width:"14px"}} />
        );
    }

    
    
    renderComp.push(
        <div key="secondFiller" style={{width:"43%"}} />
    );

    conf.forEach( function(field){ 
        if(!field.visible){
            return;
        }
        if(field.type == "price"){
            if(field.name == "total_discount_price"){
                renderComp.push(
                    <div key="thirdFiller" style={{width:"15%"}} />
                );          
            }            

            renderComp.push(
                <div style={{width:field.width}} className="amountsContainer" key={ind+"_"+field.name}>
                    <div>{sAction.translate(field.lbl, field.lblMod)}</div>
                    <div>{sAction.formatNumber(sums[field.name])}</div>
                </div>
            );
        }
        else if(field.type == "addBtn"){
            
        }
    });
    

    return <div className="QTotalContainerFirstRow QTotalContainerRow">
        {renderComp}
    </div>;
    }
}

export default QGroupTotal
