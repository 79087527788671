export default function provizeDuplicate(attrs) {
    this.load();
    const params = {action: 'duplicateProvize', record: attrs.id, type: attrs.type};
    //var self = this;

    this.rest.post('customAction', params, (returnData) => {
        if (returnData.status === true) {
            this.href('#detail/acm_Provize/' + returnData.record);
        } else {
            this.unLoad();
            this.alert(returnData.errorMessage.text);
        }
    });
}
