export default function reportWindowColumnsModuleSet(data, main, value, modul) {
  const activeColumns = this.dataGet("reportWindow/columns/active");
  var activeColumnsArray = [];
  activeColumns.forEach(column => {
      if (column.def != undefined) {
          activeColumnsArray.push(
              column.def.get("fieldModule") + "/" + column.name
          );
      }
  });

  this.dsAdd("set", "reportWindow/columns/hidden", []);
  //Kontrola musi probihat jinak podle toho jestli se data vrati z defs nebo z search
  if (!data.data) {
      data.forEachObject((row, key) => {
          const k = key.toLowerCase();
          const nameForCompare = modul + "/" + k;
          if (activeColumnsArray.indexOf(nameForCompare) === -1 && this.checkValidColumn(row)) {
              var def = row;
              if (def != undefined) {
                  def["width"] = row.width;
                  def["default"] = row.default;
                  def["show"] = true;
                  def["fieldModule"] = modul;
                  if (main != true) {
                      def["relationship"] = value;
                  }
                  this.dsAdd(
                      "add",
                      "reportWindow/columns/hidden",
                      {name: k, def: def},
                      "reportWindowColumn"
                  );
              }
          }
      });
  } else {
      data.data.columns.hidden.forEach(hiddenColumn => {
          if (!activeColumns.some(e => e.name === hiddenColumn.name) && this.checkValidColumn(hiddenColumn))
              this.dsAdd(
                  "add",
                  "reportWindow/columns/hidden",
                  hiddenColumn,
                  "reportWindowColumn"
              );
      })
  }


  this.dsProcess();
}
