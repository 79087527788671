export default function saveRecord(paramData, callback = null) {
  var self = this;
  var id = paramData.id;
  this.saveRecordBase(paramData, data => {
    if (data.state === true) {
      const msg = data.data;
      self.dsClear();
      self.dataSet(paramData.prefix + "/changes", { fields: [], files: [] });
      if(typeof callback === "function"){
        callback(msg.record);
      }
      else if (paramData.type == "rightPanel" || paramData.type == "popup") {
        if(paramData.type === "rightPanel") {
          if (this.dataGet('rightPanel/closeOnSave') === true) {
            this.closeRightPanel();
            this.routeDetailView();

            return;
          } else {
            this.reloadListOnClose();
          }
        }

        const otherData = {
          subpanels: false,
          cancelPrevisouRequest: data["cancelPrevisouRequest"] ?? true
        };
        self.routeDetailView(paramData, otherData);
      } else {
        if(id == null || id == "" || id == undefined){
        window.location.hash = "#detail/" + msg.module + "/" + msg.record;
        }else{
          const otherData = {
            cancelPrevisouRequest: data["cancelPrevisouRequest"] ?? true
          }
          self.routeDetailView(paramData,otherData);
        }
      }
    } else {
      self.error(data.msg);
    }
  });
}
