import detailDefault from "../../detailDefault";

export default class detailacm_Provize extends detailDefault {
    load(sAction, data) {
        // this.changeName(sAction, data);
        const automatic = sAction.dataGet(data.prefix + '/customData/automatic_provision');

        if (!automatic ||  automatic === '0') {
            const roleList = sAction.app_strings['role_list'];
            let customRole = [];
            const filteredRole = [
                'trader',
                'consultant',
                'whom_found',
            ];

            roleList.forEachObject((value, key) => {
                if (!filteredRole.includes(key)) {
                    customRole.push({label: value, value: key});
                }
            });

            sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/fields/role/def/customOptions", customRole);
            sAction.dsProcess();
        }

        this.setReadonlyFields(sAction, data);
    }

    update(sAction, data) {
        // if (data.field === 'assigned_user_id') {
        //     this.changeName(sAction, data);
        // }
        switch (data.field) {
            case 'procenta':
                this.calculateProvize(sAction, data, data.value, null, null);
                break;
            case 'acm_provize_opportunitiesopportunities_ida':
                this.calculateProvize(sAction, data, null, data.value.id, null);
                break;
            case 'base_profit':
                this.calculateProvize(sAction, data, null, null, data.value);
                break;
            case 'role':
                this.resetOppOnRole(sAction, data);
        }

        this.setFields(sAction, data);
        // if (data.field === 'procenta') {
        //     this.calculateProvize(sAction, data);
        // }
    }

    // changeName(sAction, data) {
    //     const assignedUser = sAction.dataGet(data.prefix + '/fields/assigned_user_name/value');
    //     sAction.dsClear();
    //     sAction.dsAdd("set", data.prefix + "/fields/name/value", assignedUser)
    //     sAction.dsAdd("set", data.prefix + "/changes/fields/name", assignedUser)
    //     sAction.dsProcess();
    // }

    calculateProvize(sAction, data, percent = null, oppID = null, baseProfit = null) {
        const userID = sAction.dataGet(data.prefix + '/fields/assigned_user_name/def/id_value');
        const role = sAction.dataGet(data.prefix + '/fields/role/value');
        if (userID === '4f185056-d624-7331-f340-5f490a4175b3' &&
            ['consultant', 'trader', 'whom_found'].includes(role)
        ) {
            sAction.dsClear();
            sAction.dsAdd("set", data.prefix + "/fields/provize/value", 0)
            sAction.dsAdd("set", data.prefix + "/changes/fields/provize", 0)
            sAction.dsAdd("set", data.prefix + "/fields/procenta/value", 0)
            sAction.dsAdd("set", data.prefix + "/changes/fields/procenta", 0)
            sAction.dsProcess();

            return;
        }

        if (baseProfit === null) {
            baseProfit = parseFloat(sAction.dataGet(data.prefix + '/fields/base_profit/value'));
        }
        if (percent === null) {
            percent = sAction.dataGet(data.prefix + '/fields/procenta/value');
        }
        if (oppID === null) {
            oppID = sAction.dataGet(data.prefix + '/fields/acm_provize_opportunities_name/def/id_value');
        }

        if (baseProfit && baseProfit !== '0') {
            if (percent && percent !== 0 && percent !== '0') {
                let provize = (baseProfit) * (percent / 100);
                sAction.dsClear();
                sAction.dsAdd("set", data.prefix + "/fields/provize/value", provize)
                sAction.dsAdd("set", data.prefix + "/changes/fields/provize", provize)
                sAction.dsProcess();
            }
        } else {
            if ((percent && percent !== 0 && percent !== '0') && oppID) {
                sAction.rest.post('getOpportunityAmount', {oppID: oppID}, returnData => {
                    if (returnData.status === true) {
                        const ocekavanaHodnota = returnData.messageData.amount || 0;
                        const naklady = returnData.messageData.cost || 0;

                        let provize = (ocekavanaHodnota - naklady) * (percent / 100);
                        sAction.dsClear();
                        sAction.dsAdd("set", data.prefix + "/fields/provize/value", provize)
                        sAction.dsAdd("set", data.prefix + "/changes/fields/provize", provize)
                        sAction.dsProcess();
                    }
                });
            }
        }
    }

    setFields(sAction, data) {
        sAction.dsClear();
        let allowPercent = false;
        let percent = sAction.dataGet(data.prefix + '/fields/procenta/value');

        switch (data.field) {
            case 'procenta':
                // Nastavim proceta - zmeni se provize
                sAction.dsAdd("set", data.prefix + '/fields/provize/def/readonly', data.value != 0);
                break;
            case 'acm_provize_opportunitiesopportunities_ida':
                // Nastavim OPP - zmeni se procenta
                sAction.dsAdd("set", data.prefix + '/fields/date_payed/def/readonly', !!data.value.id);

                allowPercent = !!data.value.id;
                let baseProfit = parseFloat(sAction.dataGet(data.prefix + '/fields/base_profit/value')) ;
                if (!allowPercent && !baseProfit ) {
                    sAction.dsAdd("set", data.prefix + '/fields/provize/def/readonly', false);
                    sAction.dsAdd("set", data.prefix + '/changes/fields/procenta', null);
                    sAction.dsAdd("set", data.prefix + '/fields/procenta/value', null);
                }
                if (allowPercent || (baseProfit && baseProfit !== '0')) {
                    allowPercent = true;
                }
                sAction.dsAdd("set", data.prefix + '/fields/procenta/def/readonly', !allowPercent);

                break;
            case 'base_profit':
                allowPercent = !!(data.value && data.value !== '0');
                let oppID = sAction.dataGet(data.prefix + '/fields/acm_provize_opportunities_name/def/id_value');
                if (!allowPercent && !oppID) {
                    sAction.dsAdd("set", data.prefix + '/fields/provize/def/readonly', false);
                    sAction.dsAdd("set", data.prefix + '/changes/fields/procenta', null);
                    sAction.dsAdd("set", data.prefix + '/fields/procenta/value', null);
                }
                if (allowPercent || oppID) {
                    allowPercent = true;
                }
                sAction.dsAdd("set", data.prefix + '/fields/procenta/def/readonly', !allowPercent);

        }

        sAction.dsProcess();
    }

    resetOppOnRole(sAction, data) {
        sAction.dsClear();
        if (data.value === 'periodic' || data.value === 'public') {
            sAction.dsAdd("set", data.prefix + '/fields/acm_provize_opportunities_name/value', null);
            sAction.dsAdd("set", data.prefix + '/fields/acm_provize_opportunities_name/def/id_value', null);
            sAction.dsAdd("set", data.prefix + '/fields/acm_provize_opportunities_name/def/readonly', true);
            sAction.dsAdd("set", data.prefix + '/changes/fields/acm_provize_opportunitiesopportunities_ida', null);
            sAction.dsAdd("set", data.prefix + '/changes/fields/relate/acm_provize_opportunities_name', null);
            sAction.dsProcess();

            let newData = data;
            newData.field = 'acm_provize_opportunitiesopportunities_ida';
            newData.value = {id: '', name: ''};
            this.update(sAction, newData);
        } else {
            sAction.dsAdd("set", data.prefix + '/fields/acm_provize_opportunities_name/def/readonly', false);
            sAction.dsProcess();
        }
    }

    setReadonlyFields(sAction, data) {
        sAction.dsClear();
        let oppID = sAction.dataGet(data.prefix + '/fields/acm_provize_opportunities_name/def/id_value');
        if (oppID) {
            sAction.dsAdd("set", data.prefix + '/fields/date_payed/def/readonly', true);
        }
        let baseProfit = parseFloat(sAction.dataGet(data.prefix + '/fields/base_profit/value'));
        if (oppID || (baseProfit && baseProfit !== '0')) {
            sAction.dsAdd("set", data.prefix + '/fields/procenta/def/readonly', false);
        } else {
            sAction.dsAdd("set", data.prefix + '/fields/procenta/def/readonly', true);
        }

        if (data.prefix === 'conf/popup/data/detailView' && oppID) {
            sAction.dsAdd("set", data.prefix + '/fields/acm_provize_opportunities_name/def/readonly', true);
            sAction.dsAdd("set", data.prefix + '/fields/role/value', 'oneTime');
            sAction.dsAdd("set", data.prefix + '/changes/fields/role', 'oneTime');
        }
        sAction.dsProcess();
    }
}
