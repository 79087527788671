export default function processFileInput(paramData,callback) {
    const list = this.dataGet(paramData.prefix);
    let activeCategory = null;
    if (list.listViewTree !== undefined) {
        if (list.listViewTree !== null) {
            activeCategory = list.listViewTree.get("activeCategory");
        }
    }

    if (list.listViewTree && activeCategory) {
        var tempEl = document.createElement("input");
        tempEl.setAttribute("type", "file");
        if (paramData.multiple) {
            tempEl.setAttribute("multiple", paramData.multiple)
        }
        if (paramData.accept) {
            // povolene typy souboru pro upload, napr. "image/png,image/jpeg,image/bmp,image/gif" nebo jen "image/*" nebo ".doc,.docx,application/msword" atd.
            tempEl.setAttribute("accept", paramData.accept);
        }
        tempEl.style = "display: none";
        document.body.appendChild(tempEl);
        tempEl.addEventListener("change", e => {
            var files = [];
            tempEl.files.forEachObject((file, key) => {
                if (key != "length") {
                    files.push(file)
                }
            });
            tempEl.parentNode.removeChild(tempEl);
            if (callback != undefined) {
                callback(files)
            } else {
                this.uploadFiles(files, paramData.prefix)
            }
        });
        tempEl.click();
    } else {
        this.error(this.translate('LBL_NO_DOCUMENT_CATEGORY_SELECTED'));
    }
}
