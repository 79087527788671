import React, { Component } from 'react'
import PureComponent from '../../pure'

import Text from '../../formElements/Text'
import TextField from "@material-ui/core/TextField";

import QProduct from './QProduct'
import QGroupTotal from './QGroupTotal'
import QGroupHeader from './QGroupHeader'

import InputText from '../../formElements/InputText'
import Select from '../../formElements/Select'
import InputBase from '@material-ui/core/InputBase';

import  sAction  from "sAction";

import DescriptionIcon from "@material-ui/icons/Description";
import Button from "../../formElements/Button";

class QProductGroup extends PureComponent{
    
    constructor(props){
        super(props);

        this.state = {
            show: true,
            descFocused: false
        }
    }

    valChange(way, val){
        this.setState({show: true});
        sAction.dataSet(way, val);
    }

    toggleDisplay(){
        this.setState({show : !this.state.show})
    }

    blurDescription(way, val){
        this.setState({descFocused : false});
        this.valChange(way, val);
    }

    handleMove(type, way, ind){
        var list = sAction.dataGet(way);
        const cnt = list.size;

        let modifier = 0;

        if(type == "up"){
            if(ind == 0){
                return;
            }
            let pomGr = list.get(ind);
            list = list.delete(ind);
            list = list.insert(ind - 1, pomGr);
            
        }
        else if(type == "down"){
            if((ind + 1) >= cnt){
                return;
            }
            let pomGr = list.get(ind + 1);
            list = list.delete(ind + 1);
            list = list.insert(ind, pomGr);
        }

        
        sAction.dsClear();
        sAction.dsAdd("set", way, list);
        sAction.dsAdd("set","view/changes/forceChange", true);
        sAction.dsProcess();
    }

    render(){
    const data = this.props.data;
    const way = this.props.way;
    const ind = this.props.ind;
    const itway = way+"/"+ind;
    const currency_id = this.props.currency_id;
    const readonly = this.props.readonly;

    var renderComp = [];

    var name = data.name;

    var stageOptions =[];
    sAction.app_strings.quote_stage_dom.forEachObject((lbl, key) => {
        stageOptions.push({value: key, label: lbl});
    });

    renderComp.push(<QGroupHeader key={"header"} readonly={readonly} />);

    data.lines.forEach(function(prod,k){
        renderComp.push(<QProduct data={prod} key={k} ind={k} way={itway+"/lines"} readonly={readonly} currency_id={currency_id} />);
    });
    
//  
    var pomClass = "";
    if(!this.state.show){
        pomClass = " hidden";
    }
    const preKey = Date.now();

    var descClass = "productLineDescription";
    var descContClass = "descGroupContainer";
    //notEmpty
    var descComp = null;
    if(this.state.descFocused){
        descComp = <TextField
        className={descClass}
        multiline={true}
        type="text"
        margin="normal"
        variant="outlined"
        rows={4}
        key={preKey+"_description"}
        onFocus={() => this.setState({descFocused : true})}
        onBlur={(ev) => this.blurDescription(itway+"/description", ev.target.value)}
        disabled={readonly}
        defaultValue={data.description}
        autoFocus={true}
    />
    }
    else{
        if(data.description.length > 0){
            descContClass += " notEmpty";
        }
        descComp = <span>{data.description}</span>
    }

    var iconClass = "icon-quoteCloseGroup";
    if(!this.state.show){
        iconClass = "icon-quoteOpenGroup";
    }

    var moveGroupComp = null;

    if(!readonly){
        moveGroupComp = <div className="qProductLineButtonContainer">
        <div className={"icon-arrowUp prodLineIcon hoverBlue"} onClick={() => this.handleMove("up", way, ind)}></div>
        <div className={"icon-arrowDown prodLineIcon hoverBlue"} onClick={() => this.handleMove("down", way, ind)}></div>
    </div>;
    }

    //.
    return <div className="qGroupContainer">
        <div className="qGroupComponent">
        <div className="qGroupHeaderContainer">
            <div className="qGroupHeader">
                <div className="qGroupHeaderLeft">
                    {moveGroupComp}
                    <div className="qGroupHeaderField">
                    <div className="qGroupHeaderFieldLbl">
                    {sAction.translate("LBL_PRODUCT_GROUP", "Quotes")}:
                    </div>
                    <TextField
                        margin="normal"
                        variant="outlined"
                        id="name"
                        type="text"
                        defaultValue={name}
                        disabled={readonly}
                        key={preKey+"_name"}
                        onBlur={(ev) => sAction.updateDetailCustom("field", {way:itway+"/name", value: ev.target.value})}
                        className="qGroupNameField"
                        
                    />
                    </div>
                    <div className="qGroupHeaderField">
                    {/*<div className="qGroupHeaderFieldLbl">*/}
                    {/*{sAction.translate("LBL_PRODUCT_GROUP_STATE", "Quotes")}:*/}
                    {/*</div>*/}
                    {/*<Select */}
                    {/*    open={false} */}
                    {/*    name="bundle_stage" */}
                    {/*    options={stageOptions} */}
                    {/*    defaultValue={data.bundle_stage} */}
                    {/*    disabled={readonly}*/}
                    {/*    key={preKey+"_bundle_stage"}*/}
                    {/*    onChange={(ev) => sAction.updateDetailCustom("field", {way:itway+"/bundle_stage", value: ev.target.value})} */}
                    {/*    containerClassName="productGroupType"*/}
                    {/*/>*/}
                    </div>
                    {!readonly &&
                        <Button variant="text" className="acmCatProdBtn" onClick={() => sAction.rightPanelProduct({way:itway+"/lines"})}>
                            <div className="icon-expandIcon buttonIcon" />
                            {sAction.translate("LBL_PRODUCT_CATALOG", "Quotes")}
                        </Button>
                    }
                    <div className={"quoteHoverIcon "+iconClass} onClick={() => this.toggleDisplay()}></div>
                </div>
                {!readonly &&
                    <Button 
                    className="acmDelGroupBtn hoverRed"
                    variant="text" 
                    onClick={() => sAction.updateDetailCustom("delItem", {way:way, index:ind})}>
                    <div className="buttonIcon icon-deleteIcon" />
                    {sAction.translate("LBL_DELETE_GROUP", "Quotes")}
                </Button>}
                {!readonly &&
                <Button
                    className="acmAddGroupBtn"
                    key={"addBtnGr"}
                    variant="text"
                    onClick={() => sAction.updateDetailCustom("addProdGroup", {way:way+"/groups"})}>
                    <div className="buttonIcon icon-addGroupIcon" />
                    {sAction.translate("LBL_ADD_GROUP", "Quotes")}
                </Button>
                }
            </div>
        </div>
        <div className={"productGroupBody"+pomClass}>
        {renderComp}
        </div>
        </div>
        <div className={descContClass}>
        {descComp}     
        </div>
        
    <QGroupTotal way={itway} data={data} ind={ind} readonly={readonly}/>
    </div>;
    }
}

export default QProductGroup
