import React from "react";
import PureComponent from "../pure";

import ListViewFooter from "./ListViewFooter";

import NewListViewHeaderField from "./NewListViewHeaderField";
import NewListViewField from "./NewListViewField";
import ListViewFilterContainer from "./listViewFilterContainer";
import MoreOptions from "../formElements/MoreOptions";
import ListViewTableTree from "./listViewTableTree";
import ListViewTableDropZone from "./listViewTableDropZone";
import TickBox from "../formElements/TickBox";
import Report from "../CustomReport/Report";
import  sAction  from "sAction";
import custom from "../../action/Base/custom";

class ListViewTable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      drag: false,
    };
    this.table = React.createRef();
    this.header = React.createRef();
    this.scrollTable = React.createRef();
    this.lastHoverLine = null;
    this.scrollLeft = 0;
  }

  componentDidMount() {
    const table = this.table.current;
    const prefix = this.props.prefix;
    sAction.afterLoadListView(prefix, table, this.props.data.type);


    this.listener = (evt) => {
      sAction.afterLoadListView(prefix, table, this.props.data.type);
    }
    window.addEventListener('resize', this.listener);
  }

  componentWillUnmount(){
    window.removeEventListener('resize',this.listener);
  }

  componentDidUpdate(prevProps) {
    const prefix = this.props.prefix;
    const table = this.table.current;
    if (this.props.data.modul != prevProps.data.modul) {
      sAction.afterLoadListView(prefix, table, this.props.data.type);
    }
  }

  dragActive() {
    const data = this.props.data;
    if (data.modul == "Documents") {
      const way = this.props.prefix;
      sAction.dataSet(way + "/dropZoneActive", true);
    }
  }

  scroll(e) {
    const data = this.props.data;
    const fromTop = e.target.scrollTop;
    this.header.current.style.top = fromTop + "px";
  
    if(e.target.scrollLeft != this.scrollLeft && data.openFilterData != null){
        if(data.openFilterData.open == true){
          const prefix = this.props.prefix;
          sAction.dataSet(prefix+"/openFilterData/open",false);
        }
    }
  
  }

  subpanelLineMoreAction(e, val) {
    if (this.props.parent != undefined) {
      try {
        this.props.parent.subpanelLineMoreAction(e, val);
      } catch (error) {}
    }
  }

  mouseOver(e) {
    var elems = document.querySelectorAll(".newListViewLine.hover");
    elems.forEach(el => {
      el.classList.remove("hover");
    });

    var target = e.target;
    if (!target.classList.contains("newListViewLine")) {
      target = target.closest(".newListViewLine");
    }
    if (target !== null) {
      var index = Array.from(target.parentNode.children).indexOf(target) + 1;
      var cels = this.table.current.querySelectorAll(
        ".newListViewRow .newListViewLine:nth-child(" + index + ")"
      );
      cels.forEach(cel => {
        cel.classList.add("hover");
      });
    }
  }

  removeHover() {
    var elems = document.querySelectorAll(".newListViewLine.hover");
    elems.forEach(el => {
      el.classList.remove("hover");
    });
  }

  /**
   *
   * @param index   index of record
   * @returns {null|string} null if not found or record ID
   */
  getIdByIndex = (index) => {
    return this.props.data.records.get(index) ??  null;
  }

  /**
   *
   * @param index     index of NewListViewField
   * @param property  name of property, eg. NAME or EMAIL1
   * @returns {null|string} null if not found or property value
   */
  getPropertyValueByIndex = (index, property) => {
    const row = this.props.data.rows.filter(row => row.get('key').toLowerCase() === property.toLowerCase());
    if (row.size !== 1) {
      return null;
    }

    const record = row.get(0).records.get(index);

    return record ? record.get('value') : null;
  }

  getlistView() {
    const filterValues = sAction.getFilterKeyValuePairs(this.props.data.filter);
    const data = this.props.data;
    const prefix = this.props.prefix;
    const orderBy = data.orderBy;
    const selectCallBack = this.props.parent.props.selectCallBack;
    const selectedSize = data.selected.size;
    var fieldWithActiveFiltering = {};
    data.filter.forEach(filter => {
      fieldWithActiveFiltering[filter.field] = filter.filters;
    });
    var rows = [];
    var header = [];
    if (
      data.type != "subpanel" &&
      data.type != "reportWindow" &&
      data.selectedActive == true
    ) {
      var disabledTickBox = false;
      var selectPage = data.selectPage;
      if (data.selected == "all") {
        disabledTickBox = true;
      }
      header = [
        <div
          className="listViechCheckBoxTd listViwTableCell checkBoxRow"
          key="select"
        >
          <TickBox
            disabled={disabledTickBox}
            checked={selectPage}
            onClick={e => {
              sAction.toggleAll(!selectPage, prefix);
              sAction.dataSet(prefix + "/selectPage", !selectPage);
            }}
          />
        </div>
      ];
    }

    var checkboxColum = [];
    if (
      data.type != "subpanel" &&
      data.type != "reportWindow" &&
      data.type != "widget" &&
      data.selectedActive == true
    ) {
      data.records.forEach((record, key) => {
        var selected = false;
        var disabledTickBox = false;
        var lineRecord = null
        if (data.selected != "all") {
          lineRecord = data.selected.find(id => id === record);
          if(lineRecord != null){
            selected = true;
          }
        } else {
          selected = true;
          disabledTickBox = true;
        }
        checkboxColum.push(
          <div className="newListViewLine newListViewLineCheckBox" key={key}>
            <TickBox
              disabled={disabledTickBox}
              key={selected}
              onClick={e =>
                sAction.toggleLine(record, e.target.checked, prefix)
              }
              checked={selected}
            />
          </div>
        );
      });
    }
    data.rows.forEach((row, rowKey) => {
      const fieldName = row.def.get("name");
      const disabled = row.disabled;
      const fieldColors = data.colors.get(fieldName);
      var lines = [];
      row.records.forEach((line, lineKey) => {
        let module = line.get("module");
        if (!module) {
          module = data.modul;
        }
        lines.push(
          <NewListViewField
            index={lineKey}
            key={lineKey}
            row={row}
            rowIndex={rowKey}
            line={line}
            modul={module}
            editModule={data.modul}
            listType={data.type}
            selectCallBack={selectCallBack}
            uuid={data.records.get(lineKey)}
            prefix={prefix}
            getIdByIndex={this.getIdByIndex}
            getPropertyValueByIndex={this.getPropertyValueByIndex}
            target={this.props.target}
            forceRightPanel={this.props.forceRightPanel}
            bcColor={
              fieldColors != undefined
                ? fieldColors.get(line.get("value"))
                : null
            }
            disabled={disabled}
          />
        );
      });

      var fieldOrder = false;
      if (row.key.toLowerCase() == orderBy) {
        fieldOrder = true;
      }

      var filtering = {
        state: false
      };
      if (fieldWithActiveFiltering[row.key.toLowerCase()] != undefined) {
        fieldWithActiveFiltering[row.key.toLowerCase()].forEach(filter => {
          if (filter.footer == false) {
            filtering = {
              state: true
            };
          }
        });
      }
      header.push(
        <NewListViewHeaderField
          key={rowKey}
          prefix={prefix}
          data={row}
          filterValues={filterValues}
          asc={data.asc}
          orderBy={fieldOrder}
          filter={data.filter}
          filtering={filtering}
          openFilter={data.openFilter}
          actFiltering={data.actFiltering}
          last={data.rows.size == rowKey + 1}
          sortable={row.sortable}
        />
      );
      rows.push(
        <div
          key={rowKey}
          data-width={row.width}
          data-last={data.rows.size == rowKey + 1}
          className="newListViewRow setWidth"
        >
          {lines}
        </div>
      );
    });

    if (data.type == "subpanel") {
      var lines = [];
      data.records.forEach((record, index) => {
        if (this.props.acl && this.props.acl.get("edit") == false) {
          return;
        }
        lines.push(
          <div
            key={"subpanel_edit_" + index}
            className="newListViewLine subpanelEditButton"
          >
            <MoreOptions
            className="right"
              options={[
                {
                  label: "Odebrat",
                  value: { action: "removeRel", record: record }
                }
              ]}
              onClick={(e, val) => this.subpanelLineMoreAction(e, val)}
            >
              <div className="subpanelLineMoreOptions moreIcon" />
            </MoreOptions>
          </div>
        );
      });
      rows.push(<div key="last">{lines}</div>);
      header.push(<div key="last" className="subpanelEditButton" />);
    }

    var filter = null;
    if (data.openFilterData != null && data.openFilterData.open == true) {
      var actFilter = null;
      var way = prefix;
      data.filter.forEach((f, k) => {
        if (f.field == data.openFilterData.field) {
          actFilter = f;
          way = way + "/filter/" + k;
        }
      });
      filter = (
        <ListViewFilterContainer
          prefix={prefix}
          way={way}
          actFilter={actFilter}
          index={data.filter.size}
          open={data.openFilterData.open}
          def={data.openFilterData.def}
          offset={data.openFilterData.offset}
        />
      );
    }

    var tree = null;
    if (data.listViewTree != null && data.listViewTree.tree !== null) {
      //if (data.type != "rightPanelQuotes") {
      tree = <ListViewTableTree data={data.listViewTree} way={prefix} />;
      // }
    }

    var tableLinesContainerClass = "newListViewLines";
    if (data.actFiltering == true) {
      tableLinesContainerClass += " activeFiltering";
    }

    let errorMessage = "";
    if (!data.records || !data.records.size) {
      errorMessage = <div className="listviewMessage">{sAction.translate("LBL_NO_MORE_RECORDS")}</div>;
    }


    return (
      <React.Fragment>
        {data.dropZoneActive == true && <ListViewTableDropZone way={prefix} />}
        {tree}
        <div className="newListView" ref={this.table}>
          <div className="newListViewContent">
            {data.type != "reportWindow" && (
              <div className="filterContainer">
                <div
                  onClick={() => sAction.activateListViewFiltering(prefix)}
                  className="icon-filter listViewFilterIcon"
                />
              </div>
            )}

            <div
              onScroll={e => this.scroll(e)}
              className="newListViewContentInner"
            >
              <div ref={this.header} className="newListViewHeader">
                {header}
              </div>
              <div
                onMouseOver={e => this.mouseOver(e)}
                onMouseLeave={() => this.removeHover()}
                className={tableLinesContainerClass}
              >
                {data.type != "subpanel" &&
                  data.type != "reportWindow" &&
                  data.type != "widget" &&
                  data.selectedActive == true && (
                    <div className="newListViewRow checkBoxRow">
                      {checkboxColum}
                    </div>
                  )}
                {rows}
              </div>
              {errorMessage}
            </div>
            {filter}
          </div>
          {data.type != "subpanel" && (
            <ListViewFooter
              module={data.modul}
              filter={data.filter}
              offset={data.offset}
              limit={data.limit}
              rowCount={data.rowCount}
              rowTotalCount={data.rowTotalCount}
              selected={selectedSize}
              selectedActive={data.selectedActive}
              page={data.page}
              prefix={prefix}
              type={data.type}
            />
          )}
          {data.selected.size != 0 && data.type == "popup" && (
            <div
              onClick={() =>
                selectCallBack({
                  id:
                    data.selected !== "all"
                      ? data.selected.toJS()
                      : data.selected
                })
              }
              className="listViewPopupSelectButton"
            >
              {sAction.translate("LBL_SELECT") +
                " (" +
                (data.selected.size
                  ? data.selected.size
                  : sAction.translate("LBL_ALL")) +
                ")"}
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }

  render() {
    const data = this.props.data;
    const way = this.props.prefix;
    var content = null;
    var customClass = "";
    if (data.category == "list" || data.category == null) {
      content = this.getlistView();
    } else if (data.category == "customReport") {
      const reportId = data.customData.get("reportId");
      content = (
        <Report
          data={data.customData}
          way={way + "/customData"}
          reportId={reportId}
          paramData={{ isDashboard: true, listview: true }}
        />
      );
      customClass = "customReport";
    }

    return (
      <div
        className={"newListViewContainer " + customClass}
        onDragOver={e => e.preventDefault()}
        onDragEnter={() => this.dragActive()}
      >
        {content}
      </div>
    );
  }
}
export default ListViewTable;
