export default function changedOppStage(params) {
    this.load();
    const onSuccess = () => {
        this.dsClear();
        this.dsAdd('set', params.way + '/fields/sales_stage/value', params.value);
        this.dsAdd('set', params.way + '/changes/fields/sales_stage', params.value);
        this.dsProcess();

        const saveData = {
            prefix: params.way,
            id: params.id,
            module: 'Opportunities',
            canSave: true,
            type: null,
        };
        this.saveRecord(saveData);
    };

    if (params.value === 'new') {
        let prevValue = this.dataGet(params.way + '/fields/sales_stage/value');
        if (!['invoiced', 'finished'].includes(prevValue)) {
            onSuccess();
            return;
        }
    }

    this.rest.post('canUserChangeOppStage', {oppID: params.id, stage: params.value}, returnData => {
        this.unLoad();
        if (returnData.status === true) {
            onSuccess();
        } else {
            this.error(this.translate(returnData.errorMessage.text));
        }
    });

}
