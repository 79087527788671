import React from "react";
import InputField from "../inputFieldClass";

import Select from "../../formElements/Select";
import InputText from "../../formElements/InputText";

import sAction from "sAction";

export default class AcmDynamicEnum extends InputField {
  constructor(props) {
    super(props);
    this.state = {
      showForm: false,
      options: null,
      type: "enum",
      inputError: false,
    };
    this.input = React.createRef();
  }
  componentDidMount() {
    sAction.load();
    const data = this.props.data;
    const actLang = sAction.dataGet("conf/language");
    sAction.openDynamicEnum(this.props.way, data, returnData => {
      const lines = returnData.lines;

      var linesOptions = [];
      if (returnData.canAddNew == true) {
        linesOptions.push({ label: sAction.translate("LBL_ADD_NEW_VALUE"), value: "acm_add_new",type:"button" });
      }
      lines.forEach((line, index) => {
        const json = line.language_json;
        const lang = sAction.fromJSON(sAction.htmlDecode(json));
        var value = lang[actLang];
        if (value == undefined || value == "" || value == null) {
          value = line.get("value");
        }
        linesOptions.push({ value: line.value, label: value });
      });
      this.setState({
        options: linesOptions,
        showForm: true
      });
      sAction.unLoad();
    });
  }

  // Stara verze s restovym volanim
  // componentDidMount() {
  //   sAction.load();
  //   const data = this.props.data;
  //   const actLang = sAction.dataGet("conf/language");
  //   sAction.openDynamicEnum(this.props.way, data, returnData => {
  //     const lines = returnDat a.lines;
  //
  //     var linesOptions = [];
  //     if (returnData.canAddNew == true) {
  //       linesOptions.push({ label: sAction.translate("LBL_ADD_NEW_VALUE"), value: "acm_add_new",type:"button" });
  //     }
  //     lines.forEach((line, index) => {
  //       const json = line.language_json;
  //       const lang = sAction.fromJSON(sAction.htmlDecode(json));
  //       var value = lang[actLang];
  //       if (value == undefined || value == "" || value == null) {
  //         value = line.get("value");
  //       }
  //       linesOptions.push({ value: line.value, label: value });
  //     });
  //     this.setState({
  //       options: linesOptions,
  //       showForm: true
  //     });
  //     sAction.unLoad();
  //   });
  // }
  selectItem(e) {
    const prefix = this.props.prefix
    const data = this.props.data;
    const value = e.target.value;

    if (value == "acm_add_new") {
      this.setState({
        type: "input",
      })
    } else {
      const params = {
        way: this.props.way,
        name: this.props.data.name,
        value
      };
      sAction.saveField(params, false);
      sAction.clearRelatedDynamicEnums(prefix, data.def.get("options"))
    }
  }
  saveInput(e){
    const data = this.props.data;
    const value = this.input.current.value;
    const keyValue = this.normalizeString(value).replace(/ /g,"_");
    if(value == ""){
      this.setState({inputError:true})
    }else{
      const updateData = {
        enum: data.def.get("options"),
        key: keyValue,
        value: value
      }
      sAction.load();
      sAction.updateDynamicEnum(updateData,ret => {
        sAction.unLoad(); 
        const params = {
          way: this.props.way,
          name: this.props.data.name,
          value:keyValue
        };
        sAction.app_strings["dynamicEnum"][data.def.get("options")][keyValue] = value
        sAction.saveField(params, true);
      })
    }
  }
  keyUpCall(e){
    const keyCode = e.keyCode
    if(keyCode == 13){
      this.saveInput(e);
    }
  }
  normalizeString (string) {
    return string.split('').map(function (letter) {
        let i = this.accents.indexOf(letter)
        return (i !== -1) ? this.out[i] : letter
      }.bind({
        accents: 'ÀÁÂÃÄÅĄĀāàáâãäåąßÒÓÔÕÕÖØŐòóôőõöøĎďDŽdžÈÉÊËĘèéêëęðÇçČčĆćÐÌÍÎÏĪìíîïīÙÚÛÜŰùűúûüĽĹŁľĺłÑŇŃňñńŔŕŠŚŞšśşŤťŸÝÿýŽŻŹžżźđĢĞģğěščřžýáíéúůĚŠČŘŽÝÁÍÉÚŮ',
        out: 'AAAAAAAAaaaaaaaasOOOOOOOOoooooooDdDZdzEEEEEeeeeeeCcCcCcDIIIIIiiiiiUUUUUuuuuuLLLlllNNNnnnRrSSSsssTtYYyyZZZzzzdGGggescrzyaieuuESCRZYAIEUU'
      })
    ).join('')
  }
  render() {
    const data = this.props.data;
    const key = data.value;
    let options = [];
    if (sAction.app_strings["dynamicEnum"] && data.def && data.def.get("options") && sAction.app_strings["dynamicEnum"][data.def.get("options")]) {
        options = sAction.app_strings["dynamicEnum"][data.def.get("options")];
    } else {
      console.log('Chybějící dynamický číselník');
    }
    const value = options[key];
    return (
      <React.Fragment>
        {this.state.showForm == true ? (
          this.state.type == "enum" ?
            <Select
              options={this.state.options}
              open={true}
              autoFocus={true}
              onChange={e => this.selectItem(e)}
            />
            :
            <>
              <div className="inputContainer">
                <InputText
                  autoFocus={true}
                    onKeyUp={e => this.keyUpCall(e)}
                  //  onKeyDown={e => this.onKeyDown(e)}
                  myRef={this.input}
                  onBlur={e => this.saveInput(e)}
                  error={this.state.inputError}
                  //  defaultValue={value}
                  id={data.name}
                  name={data.name}
                />
              </div>
              <div className="buttonContainer">
                <div
                  tabIndex="3"
                  onClick={() => this.cancel()}
                  className=" inputEditButton"
                >
                  <div className={"icon-detailCancel"} />
                </div>
              </div>
            </>
        ) : (
            <div className="valueContainer hover null">
              <span id="dynamicenum_c">{value}</span>
            </div>
          )}
      </React.Fragment>
    );
  }
}
