
import $ from "jquery";
import detailDefault from "../../detailDefault";
export default class detailMeetings extends detailDefault {
  load(sAction, data) {
    var prefixInv = data.prefix + "/customData/invitees";

    if (data.view == "calendar" || data.view == "home") {
      prefixInv = data.prefix.replace("/detailView", "");
      prefixInv += "/invitees";
    }

    /*
    if(data.view == "calendar"){
      prefixInv = "calendar/invitees"
    }
    */
    this.searchInvitees(sAction, data.prefix, prefixInv);

    if (data.view != "calendar" && data.view != "home") {
      const id = sAction.dataGet(data.prefix + "/id");
      if (!id) {
        const date = new Date();
        const year = date.getFullYear();
        var month = date.getMonth() + 1;
        if (month < 10) {
          month = "0" + month
        }
        var day = date.getDate();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        if (minutes > 44) {
          minutes = 0;
          hours++;
          if (hours > 23) {
            hours = 0;
            day++;
          }
        }
        if (day < 10) {
          day = "0" + day;
        }
        if (hours < 10) {
          hours = "0" + hours;
        }
        minutes = Math.round(minutes / 30) * 30;
        if (minutes < 10) {
          minutes = "0" + minutes
        }
        const today = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":00";
        sAction.dsClear();
        sAction.dsAdd("set", data.prefix + "/fields/date_start/value", today)
        sAction.dsAdd("set", data.prefix + "/changes/fields/date_start", today)
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", 0)
        sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", 15)
        sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", 0)
        sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", 15)
        sAction.dsProcess();
      }
    }
    this.locationLink(sAction, data.prefix);

  }

  update(sAction, data) {
    if (data.field == "parent_id") {
      console.log("UPDATE", data.prefix);
      var prefixInv = data.prefix + "/customData/invitees";
      if (data.view == "calendar" || data.view == "home") {
        var prefixInv = data.prefix.replace("/detailView", "").replace("/meetingView", "");
        prefixInv += "/invitees";
      }
      else if (data.view != "detail") {
        return;
      }
      this.searchInvitees(sAction, data.prefix, prefixInv);
      //checkni prez store(spesl funkce)
    } else if (data.field === 'date_end') {
      let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
      start = new Date(start);
      let end = new Date(data.value);
      let diff = Math.abs(end - start);
      let mins = (Math.floor(diff / (1000 * 60))) % 60;
      let hours = Math.floor(diff / (1000 * 60 * 60));
      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/duration_hours/value", hours);
      sAction.dsAdd("set", data.prefix + "/fields/duration_hours/def/minutesValue", mins);
      sAction.dsAdd("set", data.prefix + "/changes/fields/duration_hours", hours);
      sAction.dsAdd("set", data.prefix + "/changes/fields/duration_minutes", mins);
      sAction.dsProcess();
    } else if (data.field === 'duration_hours' || data.field === 'date_start') {
      console.log('DANIEL', data.value);
      let hours = sAction.dataGet(data.prefix + "/fields/duration_hours/value");
      let mins = sAction.dataGet(data.prefix + "/fields/duration_hours/def/minutesValue");
      let start = sAction.dataGet(data.prefix + '/fields/date_start/value');
      start = new Date(start);
      let end = new Date(start.getTime() + (1000 * 60 * (mins + hours * 60)));

      const year = end.getFullYear();
      var month = end.getMonth() + 1;
      if (month < 10) {
        month = "0" + month
      }
      var day = end.getDate();
      var hrs = end.getHours();
      var minutes = end.getMinutes();
      if (minutes > 44) {
        hours++;
        if (hours > 23) {
          hours = 0;
          day++;
        }
      }
      if (day < 10) {
        day = "0" + day;
      }
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes
      }
      let endDate = year + "-" + month + "-" + day + " " + hrs + ":" + minutes + ":00";
      sAction.dsClear();
      sAction.dsAdd("set", data.prefix + "/fields/date_end/value", endDate);
      sAction.dsAdd("set", data.prefix + "/changes/fields/date_end", endDate);
      sAction.dsProcess();
    }
    this.locationLink(sAction, data.prefix);
  }

  detailViewLoad(sAction, data) {
    console.log("VIEW LOAD", data);
  }
  detailViewUpdate(sAction, data) {
    console.log("update Detail");
  }

  locationLink(sAction, prefix) {

    const location = sAction.dataGet(prefix + "/fields/location");
    const value = location.value;
    if (value != null) {
      if (value.startsWith("http") || value.startsWith("www")) {
        sAction.dataSet(prefix + "/fields/location/def/type", "url")
      }
    }
  }
  searchInvitees(sAction, prefix, prefixInv) {
    console.log("PARENT", prefix);
    const parent = sAction.dataGet(prefix + "/fields/parent_name");
    if (parent && parent.def && parent.def.get("parent_type_value") == "Accounts" && parent.def.get("id_value") != "") {
      sAction.searchInvitees({ parent: parent.toJS() }, prefixInv);
    }
  }
}
