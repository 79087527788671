import React from "react";
import  sAction  from "sAction";

import InputText from "../../formElements/InputText";
import AcmDate from "../../formElements/AcmDate";
import moment from "moment";

import Select from "../../formElements/Select";

export default class AuditLog extends React.PureComponent {
  _isMounted = false;

  constructor(props) {
    super(props);

    if (!props.parentId) {
      sAction.popupHide();
    }

    this.state = {
      data: [],
      audited_fields: "",
      loaded: false,
      showLog: false,
      sortBy: "",
      asc: true,
      //max: 0,
      page: 1,
      searchData: {
        field_name: "",
        after_value_string: "",
        before_value_string: "",
        date_created: undefined,
        parent_id: props.parentId,
        created_by: "",
        created_by_id: "",
        skip_audited_fields: 0,
        limit: 10
      }
    };
    this.resizeColumn = React.createRef();
    this.interval = null;
    this.emptySearchCount = 999;
    this.thElm = "";
    this.startOffset = "";
  }

  tdmousemove = e => {
    if (this.thElm) {
      let width = this.startOffset + e.pageX;
      if (width < 100) width = 100;
      if (width > 500) width = 500;

      this.thElm.style.width = width + "px";
    }
  };

  tdmouseup = () => {
    this.thElm = undefined;
  };

  componentDidMount() {
    this._isMounted = true;

    this.find(1, this.state.searchData);

    const self = this;
    Array.prototype.forEach.call(
      document.querySelectorAll(".homeTable thead th"),
      function(th) {
        th.style.position = "relative";
        var div = document.createElement("div");
        div.innerHTML = "&nbsp;";
        div.style.top = "0";
        div.style.right = "0";
        div.style.bottom = "0";
        div.style.width = "5px";
        div.style.position = "absolute";
        div.style.cursor = "col-resize";
        div.addEventListener("mousedown", function(e) {
          self.thElm = th;
          self.startOffset = th.offsetWidth - e.pageX;
        });

        th.appendChild(div);
      }
    );

    document
      .querySelector(".homeTable thead tr")
      .addEventListener("mousemove", this.tdmousemove);
    document
      .querySelector(".homeTable thead tr")
      .addEventListener("mouseup", this.tdmouseup);
  }

  sort = (sortBy = null) => {
    const data = [...this.state.data];

    let asc = !this.state.asc;
    if (this.state.sortBy != sortBy) asc = true;

    if (this.state.page == 1 && data.length < this.state.searchData.limit) {
      //pokud je v DB jen 1 stranka vysledku, razeni probiha lokalne
      data.sort((a, b) => {
        var titleA = a[sortBy] ? a[sortBy].toLowerCase() : "";
        var titleB = b[sortBy] ? b[sortBy].toLowerCase() : "";

        if (asc) {
          if (titleA < titleB) return -1;
          if (titleA > titleB) return 1;
        } else {
          if (titleA < titleB) return 1;
          if (titleA > titleB) return -1;
        }

        return 0;
      });
    } else {
      //pokud je v DB vice stranek vysledku, razeni probiha na backendu
      const searchData = {
        ...this.state.searchData,
        sort_by: sortBy,
        sort_order: asc ? "ASC" : "DESC"
      };
      this.setState({ searchData });

      this.find(1, searchData);
    }

    this.setState({ data, sortBy, asc });
  };

  handleChange = ({target: {name, value}}) => {
    let timeout = 1000;

    const searchData = {
      ...this.state.searchData,
      [name]: value
    };
    this.setState({ searchData });

    //pokud je pocet nalezenych vysledku mensi, nez zvolena polozka noveho limitu, nevola se zbytecne DB
    if (name == "limit") {
      timeout = 1;
      const x = parseInt(value, 10);
      const z = parseInt(this.state.data.length, 10);

      const c = [10, 25, 50, 100, 250];

      let nejblizsi = 10;
      for (let i = 0; i < c.length; i++) {
        if (c[i] >= z) {
          nejblizsi = c[i];
          break;
        }
      }
      if (x > z && z < nejblizsi) {
        // console.log("šetřím db");
        return;
      }
    }

    if (
      this.state.page == 1 &&
      this.emptySearchCount < this.state.searchData.limit
    ) {
      //pokud je v DB jen 1 stranka vysledku, vyhledavani probiha lokalne
      // console.log("lokalni vyhledavani ???");
    } //else {
    //pokud je v DB vice stranek vysledku, vyhledavani probiha na backendu
    clearTimeout(this.interval);
    this.interval = setTimeout(() => {
      this.find(1, searchData);
    }, timeout);
    //}
  };

  componentWillUnmount() {
    this._isMounted = false;

    clearTimeout(this.interval);
    document
      .querySelector(".homeTable thead tr")
      .removeEventListener("mousemove", this.tdmousemove);
    document
      .querySelector(".homeTable thead tr")
      .removeEventListener("mouseup", this.tdmouseup);
  }

  find = (page, searchData = {}) => {
    if (page < 1) return;

    if (!searchData.parent_id) return;

    this.setState({ loaded: false });

    const search = { ...searchData };

    if (search["date_created"]) {
      if (search["date_created"] === "1970-01-01") {
        search["date_created"] = "";
      } else {
        search["date_created"] = sAction.dateToMysql(moment(search["date_created"]).format(sAction.getDateFormat()));
      }
    }

    sAction.rest.post(
      "getAudits",
      { module: this.props.module, page, searchData: search },
      data => {
        if (!this._isMounted) return;
        // console.log("find()", search);
        // console.log(data);

        this.setState({
          data: data.data,
          searchData: { ...this.state.searchData, skip_audited_fields: 1 },
          loaded: true,
          //max: data.max,
          page
        });
        if (data.audited_fields) {
          this.setState({
            audited_fields: data.audited_fields
          });
        }

        if (
          search["field_name"] === "" &&
          search["after_value_string"] === "" &&
          search["before_value_string"] === "" &&
          search["date_created"] === "" &&
          search["created_by"] === ""
        ) {
          this.emptySearchCount = data.data.length;
        }
      }
    );
  };

  toggleAuditedFields = e => {
    e.preventDefault();
    this.setState(prevState => ({ showLog: !prevState.showLog }));
  };

  render() {
    const {
      data,
      showLog,
      loaded,
      page,
      sortBy,
      asc,
      searchData,
      audited_fields
    } = this.state;
    const {
      field_name,
      before_value_string,
      after_value_string,
      created_by,
      date_created,
      limit
    } = searchData;

    const sort = asc ? "sorting_asc" : "sorting_desc";
    const startingCount = (page - 1) * limit;
    const length = data.length;
    const endingCount = length
      ? length + startingCount
      : length + startingCount + limit;

    let table = "";

    if (loaded === false) {
      table = (
        <tr>
          <td colSpan="5">{sAction.translate("LBL_SEARCHING","Audit")}</td>
          {/* Vyhledávání... */}
        </tr>
      );
    } else if (!length) {
      table = (
        <tr>
          <td colSpan="5">{sAction.translate("LBL_NO_RESULTS","Audit")}</td>
          {/* Žádné výsledky... */}
        </tr>
      );
    } else {
      table = data.map(r => {
        r.field_name = r.field_name ? r.field_name.replace(":", "") : "";
        r.before_value_string= sAction.mysqlToDate(r.before_value_string, true);
        r.after_value_string= sAction.mysqlToDate(r.after_value_string, true);
        const dateTime = moment(new Date(r.date_created)).format(sAction.getDateFormat("moment")+" "+sAction.getTimeFormat("moment"));
        const link = r.created_by_id ? (
          <a href={`#detail/Users/${r.created_by_id}`}>{r.created_by}</a>
        ) : (
          <span>{r.created_by}</span>
        );


        return (
          <tr key={r.id}>
            <td>
              <span title={r.field_name}>{r.field_name}</span>
            </td>
            <td>
              <span title={r.before_value_string || r.before_value_text}>{r.before_value_string || r.before_value_text}</span>
            </td>
            <td>
              <span title={r.after_value_string || r.after_value_text}>{r.after_value_string || r.after_value_text}</span>
            </td>
            <td>
              <span title={dateTime}>{dateTime}</span>
            </td>
            <td>
              <span title={r.created_by}>{link}</span>
            </td>
          </tr>
        );
      });
    }

    const options = [
      { label: 10, value: 10 },
      { label: 25, value: 25 },
      { label: 50, value: 50 },
      { label: 100, value: 100 }, 
      { label: 250, value: 250 }
    ];

    return (
      <React.Fragment>
        <div className="auditLogContainer">
          <div className="homeTableContainer" style={{minHeight: 200}}>
            <table className="homeTable">
              <thead>
                <tr ref={this.resizeColumn}>
                  <th className={sortBy != "field_name" ? "sorting" : sort} style={{minWidth: 150}}>
                    <InputText
                      margin="dense"
                      label={sAction.translate("LBL_FIELD_NAME", "Audit")}
                      type="text"
                      name="field_name"
                      value={field_name}
                      onChange={this.handleChange}
                    />
                    <div
                      className="sort"
                      onClick={() => this.sort("field_name")}
                    >
                      &nbsp;
                    </div>
                  </th>
                  <th className={sortBy != "before_value_string" ? "sorting" : sort} style={{minWidth: 165}}>
                    <InputText
                      margin="normal"
                      label={sAction.translate("LBL_OLD_NAME", "Audit")}
                      type="text"
                      name="before_value_string"
                      value={before_value_string}
                      onChange={this.handleChange}
                    />
                    <div
                      className="sort"
                      onClick={() => this.sort("before_value_string")}
                    >
                      &nbsp;
                    </div>
                  </th>
                  <th className={sortBy != "after_value_string" ? "sorting" : sort} style={{minWidth: 165}}>
                    <InputText
                      margin="dense"
                      label={sAction.translate("LBL_NEW_VALUE", "Audit")}
                      type="text"
                      name="after_value_string"
                      value={after_value_string}
                      onChange={this.handleChange}
                    />
                    <div
                      className="sort"
                      onClick={() => this.sort("after_value_string")}
                    >
                      &nbsp;
                    </div>
                  </th>
                  <th className={sortBy != "date_created" ? "sorting" : sort} style={{minWidth: 175}}>
                    <div className="acmInputContainer" style={{paddingTop:"13px"}}>
                    <AcmDate
                      label={sAction.translate("LBL_LIST_DATE", "Audit")}
                      value={date_created}
                      onChange={date_created => this.handleChange({ target: {name: "date_created", value: date_created ? date_created : "" }})}
                    />
                    </div>
                    <div
                      className="sort"
                      onClick={() => this.sort("date_created")}
                    >
                      &nbsp;
                    </div>
                  </th>
                  <th className={sortBy != "created_by" ? "sorting" : sort} style={{minWidth: 165}}>
                    <InputText
                      margin="dense"
                      label={sAction.translate("LBL_CREATED_BY", "Audit")}
                      type="text"
                      name="created_by"
                      value={created_by}
                      onChange={this.handleChange}
                    />
                    <div
                      className="sort"
                      onClick={() => this.sort("created_by")}
                    >
                      &nbsp;
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>{table}</tbody>
            </table>
          </div>

          <div className="listViewFooter">
            <div className="countInfoContainer footerBlock">
              <div className="countInfo">
                {startingCount + 1} - {endingCount}
              </div>
            </div>
            <div className="alphabetContainer footerBlock homeTableResultOnPageContainer">
              <div>{sAction.translate("LBL_RESULTS_ON_PAGE","Audit")}:</div>
              {/* Počet výsledků na stránku */}
              {'  '}
              <div className="homeTableResultOnPage">
                <Select
                  value={limit}
                  options={options}
                  name="limit"
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className="paginationContainer footerBlock">
              <div className="pagination">
                <div
                  className="arrow iconPageStart"
                  onClick={() => (page != 1 ? this.find(1, searchData) : "")}
                />
                <div
                  className="arrow iconPageBack"
                  onClick={() => this.find(page - 1, searchData)}
                />
                <div className="pageCount">
                  {sAction.translate("LBL_ACTPAGE") + " " + page}
                </div>
                <div
                  className="arrow iconPageNext"
                  onClick={() =>
                    length == limit ? this.find(page + 1, searchData) : ""
                  }
                />
              </div>
            </div>
          </div>
          <div>
          <br />
          <a href="#" onClick={this.toggleAuditedFields}>
            {sAction.translate("LBL_AUDITED_FIELDS", "Audit")}
          </a>
          <br />
          {showLog && audited_fields}
          <br />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
