export default function changedOppStage(params) {
    if (isProduct(this, params)) {
        this.dsClear();
        this.dsAdd('set', params.way + '/fields/quote_stage/value', params.value);
        this.dsAdd('set', params.way + '/changes/fields/quote_stage', params.value);
        this.dsProcess();

        const saveData = {
            prefix: params.way,
            id: params.id,
            module: 'Quotes',
            canSave: true,
            type: null,
        };
        this.saveRecord(saveData);
    } else {
        this.error(this.translate('LBL_SAVE_PREVENTED_MISSING_PRODUCT'));
    }
}

function isProduct(sAction, data) {
    let allowSave = false;

    let groups = sAction.dataGet(data.way + '/customData/productData/groups');
    if (groups) {
        groups = groups.toJS();
        groups.forEachObject((value, key) => {
            value['lines'].forEachObject((line, lKey) => {
                if (line['name']) {
                    allowSave = true;
                }
            });
        });
    }

    return allowSave;
}
