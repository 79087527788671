export default { 
    fields: [
        {
            name: "quantity",
            type: "number",
            lbl: "LBL_LIST_QUANTITY",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 5
        },
        {
            name: "name",
            type: "relateProd",
            lbl: "LBL_LIST_PRODUCT_NAME",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 38
        },
        // {
        //     name: "mft_part_num_int",
        //     type: "text",
        //     lbl: "LBL_MFT_PART_NUM_INT",
        //     lblMod : "ProductTemplates",
        //     visible: true,
        //     disabled: false,
        //     width: 7
        // },
        // {
        //     name: "mft_part_num",
        //     type: "text",
        //     lbl: "LBL_LIST_LBL_MFT_PART_NUM",
        //     lblMod : "Products",
        //     visible: true,
        //     disabled: false,
        //     width: 10
        // },
        // {
        //     name: "tax_class",
        //     type: "tax_class",
        //     lbl: "LBL_LIST_TAXCLASS",
        //     lblMod : "Quotes",
        //     visible: true,
        //     disabled: false,
        //     width: 10
        // },
        // {
        //     name: "cost_price",
        //     type: "price",
        //     lbl: "LBL_LIST_COST_PRICE",
        //     lblMod : "Quotes",
        //     visible: true,
        //     disabled: false,
        //     width: 9
        // },
        {
            name: "list_price",
            type: "price",
            lbl: "LBL_LIST_LIST_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 12
        },
        {
            name: "discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod : "Quotes",
            visible: true,
            disabled: false,
            width: 12
        },
        {
            name: "discount_select",
            type: "discount_select",
            lbl: "",
            lblMod : "Products",
            visible: true,
            disabled: false,
            width: 5
        },
        {
            name: "discount_price",
            type: "price",
            lbl: "LBL_LIST_DISCOUNT_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        {
            name: "total_price",
            type: "price",
            lbl: "LBL_LIST_TOTAL_PRICE",
            lblMod : "Quotes",
            visible: true,
            disabled: true,
            width: 12
        },
        {
            name: "opce_c",
            type: "checkbox",
            lbl: "LBL_LIST_OPCE",
            lblMod : "Quotes",
            visible: false,
            disabled: false,
            width: 5
        }
    ],
    sums : [
        {
            type: "addBtn",
            visible: true,
            width: "41%",
        },
        {
            type: "space",
            visible: true,
            width: "20px",
        },
        // {
        //     name: "total_cost_price",
        //     type: "price",
        //     lbl: "LBL_TOTAL_COST_PRICE",
        //     lblMod: "Quotes",
        //     visible: true,
        //     width: "9%"
        // },
        {
            name: "total_list_price",
            type: "price",
            lbl: "LBL_SUBTOTAL",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            name: "total_discount_amount",
            type: "price",
            lbl: "LBL_LIST_DEAL_TOT",
            lblMod: "Quotes",
            visible: true,
            width: "12%"
        },
        {
            type: "space",
            visible: true,
            width: "38%",
        },
        {
            name: "total_discount_price",
            type: "price",
            lbl: "LBL_NEW_SUB",
            lblMod: "Quotes",
            visible: true,
            width: "14%"
        }
    ]
};
