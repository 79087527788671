export default function checkPSC(psc, prefix = "view", fieldPrefix = "") {
  psc = psc.replace(/[^0-9]+/g, "");

  if (!psc) return;

  this.load();
  // Copy billing address to shipping address
  const CPA = this.dataGet(prefix + '/fields/copy_address/value') == 1 && fieldPrefix == 'billing';
  this.rest.post("detailapi/checkpsc", { psc }, data => {
    data.forEachObject((v,k) => {
      if (k.charAt(0) === "_") {
        data[fieldPrefix+k] = v;
        if (CPA) {
          data['shipping' + k] = v;
        }
        delete data[k];
      }
    });
    this.updateFields(data, prefix);
  });
}
