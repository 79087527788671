
import detailDefault from "../../detailDefault";
export default class detailQuotes extends detailDefault{
    load(sAction,data) {

    }
    update(sAction,data) {
        switch (data.field) {
            case "danova_sazba":
                sAction.dataSet(data.prefix + "/customData/productData/dph", data.value);
                break;
            case "currency_id":
                this.changeCurrencyValues(sAction, data);
                break;
            case "cnb_value":

                const currency_id = sAction.dataGet(data.prefix+"/fields/currency_id/value");
                if (currency_id && currency_id !== "-99") {
                    // kdyz se meni kurz v eurech napr. z 25 Kc na 20 Kc - nabidka se prepocita dle defaultni meny
                    sAction.dataSet(data.prefix+"/fields/cnb_value/value", data.prevValue);

                    // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: "-99"});
                    sAction.saveField({
                        way: data.prefix + "/fields/currency_id",
                        name: "currency_id",
                        value: "-99"
                    }, true);

                    sAction.dataSet(data.prefix+"/fields/cnb_value/value", data.value);

                    // this.changeCurrencyValues(sAction, {prefix: data.prefix, value: currency_id});
                    sAction.saveField({
                        way: data.prefix + "/fields/currency_id",
                        name: "currency_id",
                        value: currency_id
                    }, true);
                }

                break;
            case 'quote_stage':
                if (['sent', 'won'].includes(data.value) && !this.isProduct(sAction, data)) {
                    console.log('AAA', data);
                    sAction.dsClear();
                    sAction.dsAdd('set', data.prefix + '/fields/quote_stage/value', data.prevValue);
                    sAction.dsAdd('set', data.prefix + '/changes/fields/quote_stage', data.prevValue);
                    sAction.dsProcess();
                    // sAction.error(sAction.translate('LBL_SAVE_PREVENTED_MISSING_PRODUCT'));
                }
                break;
        }
    }

    changeCurrencyValues(sAction, data) {
        // prepocitej produkty v nabidce podle zadaneho kurzu...

        if (!data.value && data.prevValue === "-99" || data.value === "-99" && !data.prevValue) {
            return;
        }

        if (!data.value) {
            data.value = "-99";
        }

        let cnb_value = 0;
        let cnb_value_field_value = sAction.dataGet(data.prefix + "/fields/cnb_value/value");
        if (cnb_value_field_value && parseFloat(cnb_value_field_value)) {
            cnb_value = cnb_value_field_value;
        }

        sAction.dataSet(data.prefix+"/customData/productData/currency_id", data.value);

        const groups = sAction.dataGet(data.prefix + "/customData/productData/groups");
        sAction.dsClear();
        let group_i = 0;
        for (let group of groups) {
            let line_i = 0;
            for (let line of group.lines) {

                let list_price = sAction.currencyConvert(line.list_price, data.prevValue, data.value, cnb_value);
                let cost_price = sAction.currencyConvert(line.cost_price, data.prevValue, data.value, cnb_value);
                let discount_amount = sAction.currencyConvert(line.discount_amount, data.prevValue, data.value, cnb_value);

                sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/list_price", list_price);
                sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/cost_price", cost_price);
                if (line.discount_select == 0) {
                    sAction.dsAdd("set", data.prefix + "/customData/productData/groups/" + group_i + "/lines/" + line_i + "/discount_amount", discount_amount);
                }
                line_i++;
            }
            group_i++;
        }
        sAction.dsProcess();
    }

    beforeSave(sAction, data) {
        let allowSave = true;

        let stav = sAction.dataGet(data.prefix + '/fields/quote_stage/value');
        if (['sent', 'won'].includes(stav)) {
            allowSave = this.isProduct(sAction, data);
        }

        return allowSave;
    }

    isProduct(sAction, data) {
        let allowSave = false;

        let groups = sAction.dataGet(data.prefix + '/customData/productData/groups');
        if (groups) {
            groups = groups.toJS();
            groups.forEachObject((value, key) => {
                value['lines'].forEachObject((line, lKey) => {
                    if (line['name']) {
                        allowSave = true;
                    }
                });
            });
        }
        if (!allowSave) {
            sAction.error(sAction.translate('LBL_SAVE_PREVENTED_MISSING_PRODUCT'));
        }

        return allowSave;
    }
}

