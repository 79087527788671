import React, { Component } from 'react'
import PureComponent from '../../pure'

import TickBox from '../../formElements/TickBox'
import RadioGroup from '../../formElements/RadioGroup'
import Relate from '../../formElements/Relate'

import Button from "../../formElements/Button";

import DetailTabContent from "../../detailView/DetailTabContent";
import  sAction  from "sAction";

class LeadConvertModule extends PureComponent {

    handleCheck(prefix, value){
        sAction.dataSet(prefix+"/customData/checked", value);
    }

    handleSelect(prefix, item){
        if(item.id != undefined && item.id != ""){
            sAction.dsClear();
            sAction.dsAdd("set", prefix+"/customData/related_id", item.id);
            sAction.dsAdd("set", prefix+"/customData/related_name", item.name);
            sAction.dsProcess();
        }
    }

    show(e,data){
        e.stopPropagation();
        sAction.rightPanelDetail(data.module,data.record);
    }

    selectCandidate(prefix, item){
        sAction.dsClear();
        sAction.dsAdd("set", prefix+"/customData/checked", false);
        sAction.dsAdd("set", prefix+"/customData/related_id", item.record);
        sAction.dsAdd("set", prefix+"/customData/related_name", item.name);
        sAction.dsProcess();
    }

    handleRadioVal(prefix, e){
        let value = true;
        if(e.target.value != "create"){
            value = false;
        }
        sAction.dataSet(prefix+"/customData/checked", value);
    }

    componentDidMount() {
        const data = this.props.data;
        // sAction.fillDetailPredefinedFields(this.props.way, data.id);

        sAction.custom("load", {
            prefix: this.props.prefix,
            module: data.module
        });
    }

    render(){
        const prefix = this.props.prefix;
        const way = this.props.way;
        const data = this.props.data;
        const index = this.props.index;

        const tab = data.tabs.get(0);
        const customData = data.customData;
        const module = data.module;

        const checked = customData.checked;
        const preKey = Date.now();

        var customClass = "";
        if(customData.customClass !== undefined){
            customClass = " "+customData.customClass;
        }

        var reqPom = null;

        var dataRel = {};
        if(customData.related_id != undefined){
            dataRel = {value : customData.related_name, id_value: customData.related_id};
        }

        let tick = null;
        let radio = null;
        let headerStr = sAction.translate("LBL_NEW_FORM_TITLE_CL", module);
        if(customData.required){
            reqPom = <span className="required">*</span>;
            headerStr = sAction.translate("LBL_MODULE_NAME_SINGULAR", module);
            let radioVal = "select";
            if(checked){
                radioVal = "create";
            }
            
            radio = <div className="lcRadioContainer">
                <RadioGroup
                    autoFocus={true}
                    //onKeyDown={e => this.onKeyDown(e)}
                    key={module+"_"+radioVal}
                    defaultValue={radioVal}
                    options={{"create" : sAction.translate("LBL_NEW_FORM_TITLE_CL", module), "select" : sAction.translate("LBL_SELECT_RECORD", module)}}
                    onChange={e => this.handleRadioVal(prefix, e)}
                />
            </div>;
        }
        else{
            tick = <TickBox 
            className="blueTick" 
            checked={checked} 
            onChange={(value) => this.handleCheck(prefix, value)} />
        }

        var candComp = null;

        if(customData.candidates.size > 0){
            var candidates = [];
            customData.candidates.forEach(cand => {
                cand.module = data.module;
                let classCand = "";
                if(customData.related_id == cand.record){
                    classCand = " selectedCand";
                }
                candidates.push(<div key={cand.record} className={"leadConvertNewRecord"+classCand}>
                        <div onClick={e => this.show(e, cand)} className="icon-eye detailViewFieldIconEye" />
                        <a target='_blank' key={cand.record} href={'/#detail/'+data.module+'/'+cand.record}>{cand.name}</a>
                        <Button title={sAction.translate("LBL_SELECT_CANDIDATE", "Leads")} className="hoverBlue" onClick={() => {this.selectCandidate(prefix, cand)}}><div className="icon-arrowUp" /></Button>
                </div>);
            });
            candComp = <div className="leadConvertCandContainer">
                <div className="lCConvertCandLblCol">{sAction.translate("LBL_FOUND_CANDIDATES", "Leads")}:</div>
                <div className="leadConvertCandidates">
                    {candidates}
                </div>
            </div>;
        }

        var renderComp = null;
        if(checked){
            renderComp = <div className="detailView">
                <DetailTabContent
                prefix={prefix}
                module={data.module}
                index={index}
                way={prefix + "/tabs/0"}
                key={data.module}
                data={tab}
                rowCount={data.rowCount}
                id={data.id}
                fields={data.fields}
                />
            </div> ;
        }
        else if(customData.required){
            renderComp = <div className="detailView lcSelectDetailView">
                <div className={"lcSelectContainer LeadConvertRelInput"+customClass}>
                    <Relate 
                        newRecord={false} 
                        data={dataRel}
                        module={module} 
                        buttons={[]}
                        callback={(item) => this.handleSelect(prefix, item)}
                        key={preKey+module}
                    />
                </div>
            </div>;
        }

        return (
            <div className={"LeadConvertModule"+customClass}>
                <div className="detailCard">
                    <div className="header">
                        {tick}
                        <div className="title">{headerStr}</div>
                        {reqPom}
                    </div>
                    {radio}
                    {candComp}
                    {renderComp}
                </div>
            </div>
        );
    }
}
export default LeadConvertModule