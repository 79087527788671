export default function findAlterName(value){
    const alterNames = this.dataGet("reportWindow/alterName").toJS()

    for (let alterName of Object.values(alterNames)) {
        if(alterName.field.toUpperCase() === value.key){
            return alterName.vname
        }
    }
    return this.translate(value.label, value.def.get("fieldModule"));
}
