import { Map, List } from "immutable";
import { DataConstructors } from "../../../DataConstructors";

import createListViewTree from "../listView/createListViewTree";

export default function processSubpanel(data){
    var subpanel = data.subpanel
    const def = subpanel.defs
    const rows = subpanel.rows;
    const records = subpanel.records;
    const readonly = subpanel.readonly;
    
    var columnsRecord = {};
    var newData = [];
    var recordsPole = [];
    var recordsCount = null;
    if(rows != null){
    rows.forEachObject((row,rowKey) => {
      columnsRecord[rowKey] = [];
      var fieldName = rowKey.toLowerCase();
      var fieldDef = def[fieldName];
      
      records.forEach((record,key) => {

        if(fieldDef !== undefined){
          const fieldData = {
            value: record[rowKey.toLowerCase()],
          };
          // ___________________________ CUSTOM DATA K FIELDU
          if(fieldName == "name" || fieldName == "full_name" || fieldName == "document_name"){
             fieldData.id = record.id;
             fieldData.module = record.module;
             recordsPole.push({record: record.id, module: record.module});
          }
          if(fieldDef.type == "currency"){
            fieldData.currency_id = record.currency_id;
          }
          if (fieldDef.type == "parent") {
            fieldData.id = record.parent_id;
            fieldData.module = record.parent_type;
          }
          if(fieldDef.type == "relate"){
            var id = record[fieldDef.link+"|id"];
            if(id == undefined){
              id = record[fieldDef.id_name]
            }
            fieldData.module = fieldDef.module;
            fieldData.id = id
          }
          // ___________________________ CUSTOM DATA K FIELDU
      
          columnsRecord[rowKey].push(new Map(fieldData));
        }else if(row.type == "icon"){
          var fieldData = {
            module: record.module
          }
          columnsRecord[rowKey].push(new Map(fieldData));
        }
      })
      if (fieldDef != undefined && row.vname !== undefined) {
        fieldDef["fieldModule"] = subpanel.module;
        newData.push(
          new DataConstructors["ListViewRow"]({
            key: rowKey,
            label: row.vname,
            width: row.width,
            def: Map(fieldDef),
            records: List(columnsRecord[rowKey]),
            sortable: row.sortable,
            disabled:row.disabled
          })
        );
        recordsCount = columnsRecord[rowKey].length;
      }else if(row.type == "icon"){
        newData.push(
          new DataConstructors["ListViewRow"]({
            key: rowKey,
            label: row.vname,
            width: row.width,
            def: Map({fieldModule:data.module,type:"icon",name:"module"}),
            records: List(columnsRecord[rowKey]),
            sortable: row.sortable,
          })
        );
      }
    })
    }

    // ______________________________________ LISTVIEW TREE
    if (subpanel.listViewTree != null) {
      var tree = createListViewTree(subpanel.listViewTree.tree, true);
      var treeData = {
        module: subpanel.listViewTree.module,
        name: subpanel.listViewTree.name,
        field: subpanel.listViewTree.field,
        relationship: subpanel.listViewTree.relationship,
        tree: List([tree])
      };
  }
    // ______________________________________ LISTVIEW TREE END

    // COLORS 
    var listColors = {};
    if(subpanel.colors != undefined){
    subpanel.colors.forEachObject((colors, key) => {
      listColors[key] = new Map(colors);
    });
  }


    // COLORS

    var defaultFilter = {
      operandType:"relate",
      type:"eq",
      module:data.module,
      relName:subpanel.rel_name
    };
    var relDefinition = {
      type:"relate",
      module: data.module,
      relName: subpanel.rel_name
    };
    if(def != null){
      def.forEachObject((field,key) => {
        if((field.link == data.subpanel.rel_name) && 
        field.rname == "name"){
         
          relDefinition.relationship = [field.link];
          relDefinition.name = field.name;
          relDefinition.id_name = field.id_name;
          relDefinition.relName = subpanel.rel_name;

          defaultFilter.relationship = [field.link];
          defaultFilter.name = field.name;
          defaultFilter.relName = subpanel.rel_name;

        }else if(field.link == data.module.toLowerCase() && 
        field.rname == "name"){
          
          relDefinition.relationship = [field.link];
          relDefinition.name = field.name;
          relDefinition.id_name = field.id_name;
          relDefinition.relName = subpanel.rel_name;

          defaultFilter.relationship = [field.link];
          defaultFilter.name = field.name;
          defaultFilter.relName = subpanel.rel_name;
        }else if(field.type == "link" && field.module == data.module){
          if(relDefinition.relationship == undefined){
          relDefinition.relationship = [field.relationship];
          relDefinition.name = field.name;
          relDefinition.relName = subpanel.rel_name;
          defaultFilter.relationship = [field.relationship];
          defaultFilter.name = field.name;
          defaultFilter.relName = subpanel.rel_name;
          }
        }else if(key == data.subpanel.rel_name){
          defaultFilter.relationship = [field.relationship];
          defaultFilter.name = field.name;
          defaultFilter.relName = key;
        }
      })
    }
    if(defaultFilter["relationship"] == undefined){
      defaultFilter["relationship"] = [data.module.toLowerCase()]
    }
    defaultFilter.parentId = data.parentId

    var defaultFilterData = {
      subpanel: defaultFilter,
    }
    if(subpanel.listViewTree != null){
      defaultFilterData["tree"] = {
        module: subpanel.listViewTree.module,
        name: subpanel.listViewTree.name,
        field: subpanel.listViewTree.field,
        relationship: subpanel.listViewTree.relationship,
        operandType:"relate",
        type:"contArray",
      }
    }

    var order = null;
    var asc = null;
    if(subpanel.orderBy != undefined){
        const orderData = subpanel.orderBy;
        order = orderData.field.replace(/ /g, '').split(",");
        asc = orderData.direction.toLowerCase() === "asc" ? 1 : 0
    }

    var list = new DataConstructors["ListView"]({
      rowCount: recordsCount,
      offset: 0,
      limit: 10,
      orderBy: order,
      asc:asc,
      modul: subpanel.module,
      rows: List(newData),
      records:List(recordsPole),
      colors: new Map(listColors),
      type:"subpanel",
      listViewTree: new DataConstructors.listViewTree(treeData),
      defaultFilter: new Map(defaultFilterData),
    });


    var subpanelData = new DataConstructors["subpanelDef"]({
      module: subpanel.module,
      rows: list,
    })
    subpanelData = subpanelData.setIn(["def","readonly"], readonly);
    subpanelData = subpanelData.setIn(["def","open"],subpanel.open);
    subpanelData = subpanelData.setIn(["def","rel"],new Map(relDefinition));
    return subpanelData
}
