export default function oppCreateInvoiceRequest(param) {
    // invoice_request
    // param.canSave = this.dataGet(param.prefix)
    if (param.canSave) {
        if (canCreateInvoiceRequest(this, param)) {
            this.rest.post('createInvoiceTask', {userID: 'userD', oppID: param.id}, (returnData) => {
                if (returnData.status) {
                    this.dsClear();
                    this.dsAdd("set", param.prefix + "/fields/sales_stage/value", 'invoice_request');
                    this.dsAdd("set", param.prefix + "/changes/fields/sales_stage", 'invoice_request');
                    this.dsProcess();
                    this.saveRecord(param, () => {
                        this.unLoad();
                        this.alert(this.translate('LBL_INVOICE_REQUEST_DONE'));
                    });
                } else {
                    this.error(this.translate(returnData.errorMessage.text));
                }
            });
        } else {
            this.error(this.translate('LBL_OPP_DATES_NOT_FILLED'))
        }
    }
}

function canCreateInvoiceRequest(sAction, param) {
    const date_to = sAction.dataGet(param.prefix + '/fields/date_to/value');
    const duzp = sAction.dataGet(param.prefix + '/fields/duzp/value');

    return (duzp || date_to);
}
